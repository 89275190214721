@use "../partials/settings" as *;
// login formulier
#loginform {
	width: 100%;
	.login-username, .login-password {
		margin-bottom: 0;
		position: relative;
		label {
			font-weight: 400;
			background: white;
			color: $blauw;
			font-size: 14px;
			left: 15px;
			line-height: 14px;
			padding: 3px;
			position: absolute;
			top: -8px;
		}
		input {
			border: 1px solid #E7E7E7;
			height: 57px;
			line-height: 57px;
			margin-bottom: 20px;
			padding: 15px;
			width: 100%;
			&:focus {
				outline-color: $blauw;
			}
		}
	}
	.login-submit {
		#wp-submit {
			background: none;
			background: $rood;
			border: 2px solid $rood;
			border-radius: 3px;
			color: white;
			cursor: pointer;
			display: inline-block;
			font-size: 0.8em;
			font-weight: 400;
			height: 50px;
			letter-spacing: 1px;
			line-height: 47px;
			margin-top: 15px;
			max-width: 100%;
			padding: 0px 30px;
			text-align: center;
			text-decoration: none;
			text-transform: uppercase;
			transition: all .2s linear;
			white-space: nowrap;
			&:hover {
				background: darken($rood, 10%);
				border-color: darken($rood, 10%);;
				color: white;
			}
		}
	}
}