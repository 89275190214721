.page-template-page-bekeuringen {
    form {
        max-width: 500px;
    }
    fieldset input {
        line-height: 0px;
    }

    .btn {
        display: block;
    }

    .drop-area {
        position: relative;
        width: 100%;
        height: 200px;
        display: block;
        top: 0;
        left: 0;
        border: 2px dashed #333;
        text-align: center;
        line-height: 200px;

        input {
            opacity: 0.5;
            margin: 0px auto;
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            text-align: center;
            visibility: hidden;
        }
    }
}