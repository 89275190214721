@use "settings" as *;

.btn {
	background: none;
	border: 2px solid white;
	border-radius: 3px;
	color: white;
	cursor: pointer;
	display: inline-block;
	font-size: 0.8em;
	font-weight: 400;
	height: 50px;
	letter-spacing: 1px;
	line-height: 47px;
	margin-top: 15px;
	max-width: 100%;
	padding: 0px 30px;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	transition: all .2s linear;
	white-space: nowrap;
	-webkit-appearance: none;
	
	&.btn-primary {
		background: $rood;
		border-color: $rood;
		color: white;
		
		&:hover {
			background: darken($rood, 10%);
			border-color: darken($rood, 10%);;
			color: white;
			
			a {
				text-decoration: none;
			}
		}
	}
	
	&.btn-white {
		border-color: white;
		color: white;
		
		&:hover {
			background: white;
			color: $rood;
		}
	}
	
	&.btn-secondary {
		border-color: $blauw;
		color: $blauw;
		padding: 0 25px;
		text-align: center;
		
		&:hover {
			background: $blauw;
			color: white;
		}

		&__red {
			border-color: $rood;
			color: $rood;

			&:hover {
				background: $rood;
			}
		}
	}
	
	&.btn-load {
		position: relative;
		
		@keyframes example {
			from {
				width: 0%;
			}
			to {
				width: calc(100% + 4px)
			}
		}
		
		&::after {
			animation-duration: 1s;
			animation-iteration-count: infinite;
			animation-name: example;
			background-color: rgba(0, 0, 0, 0.1);
			content: "";
			height: calc(100% + 4px);
			left: -2px;
			position: absolute;
			top: -2px;
			width: 0%;
		}
	}
	
	&.btn-small {
		transform: scale(.75);
		transform-origin: left center;
		margin-top: 0;
	}
	
	&.btn-submit {
		min-width: 300px;
	}
	
	&.btn-login {
		margin-bottom: 10px;
	}
	
	&.btn-rental {
		margin-right: 10px;
		margin-top: 0;
		padding: 5px 25px;
		height: 40px;
		line-height: 30px;
		border-radius: 20px;
		text-transform: capitalize;
	}
	
	&.btn-location {
		margin-top: 0;
		width: 250px;
	}
	
	&:hover {
		background: white;
		color: #fcfcfc;
	}
	
	.fa {
		font-size: inherit;
		line-height: inherit;
		// margin: 0 0 0 5px;
		position: relative;
	}
}