@use "../partials/settings" as *;

section.text-image {
	
	.text-image__text {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: center;
		padding: 30px 0;
		
		@media (min-width: $breakpoint-md) {
			padding: 0;
		}
		
		&--has-image {
			padding: 30px 10px;
			position: relative;
			
			h2 {
				margin-top: 30px;
				
				@media (min-width: $breakpoint-lg) {
					margin-top: 0;
				}
			}
			
			@media (min-width: $breakpoint-lg) {
				padding: 45px;
			}
			
			@media (min-width: $breakpoint-xl) {
				box-shadow: 0 2px 33px 7px #EBF0FB;
				padding: 90px;
			}
			
			figure {
				height: 0;
				width: 100%;
				padding-top: 56.25%;
				overflow: hidden;
				position: relative;
				
				&.hover-img {
					display: block;
					z-index: 1;
					transition: opacity .2s ease-out;
					
					@media (min-width: $breakpoint-lg) {
						position: absolute;
						left: 0;
						top: 0;
						height: 100%;
						width: 100%;
					}
				}
				
				img {
					height: 100%;
					width: 100%;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					object-fit: cover;
					position: absolute;
				}
			}
		}
		
		h2 {
			color: $rood;
			text-align: left;
		}
	}
	
	.no-padding {
		padding: 0;
		
		@media (min-width: $breakpoint-md) {
			padding: 0 15px;
		}
	}
	
	figure {
		height: 100%;
		width: 100%;
		
		img {
			height: 100%;
			object-fit: cover;
			width: 100%;
		}
	}
	
	.btn {
		
		@media (min-width: $breakpoint-md) {
			align-self: flex-start;
		}
		
	}
	
	.order-1 {
		order: 1;
		
		@media (min-width: $breakpoint-md) {
			order: initial;
		}
	}
}