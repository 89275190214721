@use 'settings' as *;

#webshop-formulier {
	fieldset:first-of-type {
		display: block;
	}
	
	fieldset.jouw-gegevens {
		display: none;
	}
}

.producten {
	list-style: none;
	margin-bottom: 30px;
	
	.product {
		position: relative;
		
		&:last-child {
			> label {
				border-bottom: 0;
			}
		}

		label.label {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			cursor: pointer;
		}
		
		.product__inner {
			align-items: center;
			border-bottom: 2px solid #f8f9fb;
			cursor: pointer;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			padding: 20px 0px;
			transition: background .2s ease-out;
			width: 100%;
			position: relative;
			
			@media (min-width: $breakpoint-lg) {
				flex-direction: row;
				padding: 20px 40px 20px 50px;
			}
			
			&:hover {
				background: #EFF6FB;
				transition: background .2s ease-out;
			}
			
			.product__thumbnail {
				height: 150px;
				object-fit: contain;
				width: 150px;
				background-color: #f1f1f1;
				background-image: url(../img/logo.svg);
				background-repeat: no-repeat;
				background-position: center center;
				background-size: 50% auto;
				position: relative;

				img {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					object-fit: cover;
					background-color: #f1f1f1;
				}
			}
		}
		
		.checkmark {
			background: #fff;
			border: 1px solid black;
			//box-shadow: 0 0 10px 1px rgba(black, .3);
			border-radius: 5px;
			color: green;
			display: block;
			height: 25px;
			left: 10px;
			line-height: 50px;
			padding: 2px;
			position: absolute;
			top: 95px;
			transform: translateY(-50%);
			transition: opacity .2s ease-out;
			width: 25px;
			
			@media (min-width: $breakpoint-lg) {
				left: 12.5px;
				top: calc(50% - 12.5px);
				transform: translateY(0);
			}
			
			i {
				display: none;
				color: $blauw;
				
				&::before { // het icoontje
					left: 50%;
					position: absolute;
					top: 50%;
					transform: translate(-50%, -50%);
				}
			}
		}
		
		input[type=checkbox] {
			display: none;
			
			&:checked {
				// & + label {
				// 	background-color: darken(#EFF6FB, 5%);
				// 	border-color: white;
				// 	transition: background .2s ease-out;
					
				// 	.product__aantal-container, .checkmark {
				// 		display: flex;
				// 	}
					
				// 	.checkmark i {
				// 		display: block;
				// 	}
				// }

				~ .label {
					display: none;
				}

				~ .product__inner {
					background-color: darken(#EFF6FB, 5%);
					border-color: white;
					transition: background .2s ease-out;

					.checkmark i {
						display: block;
					}

					.product__aantal-container, .checkmark {
						display: flex;
					}
				}
			}
		}
		
		.product__aantal-container {
			display: none;
			text-align: center;
			
			@media (min-width: $breakpoint-lg) {
				margin-right: 50px;
				text-align: left;
			}
		}
		
		input[type=number] {
			padding: 5px 15px;
			text-align: center;
			width: 100px;
			border-radius: 5px;
			border: 1px solid $blauw;
			
			@media (min-width: $breakpoint-lg) {
				text-align: left;
			}
		}
		
		.product__titel {
			margin: 10px 15px;
			
			@media (min-width: $breakpoint-lg) {
				max-width: 250px;
			}
			
			@media (min-width: $breakpoint-xl) {
				margin: 0 auto 0 30px;
				max-width: 500px;
			}
		}
		
		.product__prijs-container {
			margin: 10px 0;
			
			@media (min-width: $breakpoint-lg) {
				margin: 0;
			}
			
			> span {
				text-align: center;
				
				@media (min-width: $breakpoint-lg) {
					text-align: right;
				}
			}
		}
	}
	
}

.totaalprijs {
	display: none;
	flex-direction: column;
	justify-content: center;
	margin-bottom: 15px;
	
	@media (min-width: $breakpoint-lg) {
		justify-content: flex-end;
		margin-right: 40px;
	}
	
	> span {
		text-align: center;
		
		@media (min-width: $breakpoint-lg) {
			text-align: right;
		}
	}
	
	input {
		align-self: center;
		-webkit-appearance: none;
		appearance: none;
		background: transparent;
		border: 0;
		color: black;
		text-align: center;
		
		@media (min-width: $breakpoint-lg) {
			align-self: flex-end;
			text-align: right;
		}
	}
}