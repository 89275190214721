@use "../partials/settings" as *;

section.section.intro {
	max-height: 550px;
	min-height: 40vh;
	position: relative;
	// overflow: hidden;
	display: flex;
	justify-content: center;
	flex-direction: column;
	
	&--small {
		max-height: 330px;
		min-height: 30vh;
	}
	
	&--micro {
		max-height: 150px;
		min-height: 150px;
		background: #EFF6FB;
		background-image: url(../img/header.jpg);
		background-position: right -1px center;
		background-repeat: no-repeat;

		h1 {
			color: $blauw;
		}
	}
	
	.container, .row {
		height: 100%;
	}
	
	.bg {
		height: 100%;
		left: 50%;
		object-fit: cover;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
	}
	
	.gradient {
		background: linear-gradient(to right, rgba(black, .5) 0%, rgba(black, .2) 100%);
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		
		@media (min-width: $breakpoint-lg) {
			background: linear-gradient(to right, rgba(black, .5) 0%, rgba(black, 0) 100%);
			width: 66%;
		}
	}
	
	.text {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: center;
		
		h1 {
			margin-bottom: 15px;
			
			@media (min-width: $breakpoint-lg) {
				font-size: 65px;
				line-height: 70px;
			}
		}
		
		p {
			
			@media (min-width: $breakpoint-sm) {
				width: 66%;
			}
			
			@media (min-width: $breakpoint-lg) {
				width: 50%;
			}
		}
		
		.btn {
			margin-top: 0;
			@media (min-width: $breakpoint-md) {
				align-self: flex-start;
			}
		}
	}
}