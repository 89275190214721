@use "settings" as *;

.header {
	position: sticky;
	top: 0;
	width: 100%;
	z-index: 22;
	
	// @media(min-width: $breakpoint-md) {
	// 	position: fixed;
	// 	top: 0;
	// 	z-index: 22;
	// }
	
	.header__main {
		background: white;
		flex-direction: column;
		padding: 10px 0px;
		position: relative;
		
		@media(min-width: $breakpoint-md) {
			height: 100px;
		}
		
		.logo {
			margin-right: auto;
			max-width: 80px;
			width: 100%;
			
			@media(min-width: $breakpoint-xxl) {
				max-width: 140px;
			}
			
			img {
				width: 100%;
			}
		}
		
		.btn-menu {
			display: block;
			margin-left: 5px;
			padding: 0;
			padding-right: 25px;
			height: 53px;
			line-height: 53px;
			color: $blauw;
			text-align: center;

			.fa { 
				font-size: 30px;
				line-height: 53px;
			}
			
			@media(min-width: $breakpoint-xl) {
				display: none;
			}
		}

		.btn {
			display: block;
			margin: 0;
		}

		.btn-reserveren {
			padding: 0px 10px;
			font-size: 0.7em;
			margin-left: 25px;
			letter-spacing: 0.5px;
			height: 45px;
			line-height: 41px;

			@media(min-width: $breakpoint-lg) {
				padding: 0px 25px;
				font-size: 0.8em;
				letter-spacing: 1px;
				margin-left: 5px;
			}
		}
		
		.btn-mijn {
			display: none;
			margin-right: 10px;
			padding: 0px 20px;
			
			@media(min-width: $breakpoint-md) {
				display: inline-block;
			}
		}
	}
	
	.nav {
		align-items: center;
		background-color: $blauw;
		-webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
		box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
		flex: 1 0 0;
		flex-direction: column;
		height: 100vh;
		justify-content: center;
		left: -100%;
		padding: 0px;
		padding-top: 50px;
		position: fixed;
		top: 0px;
		transition: left 0.2s ease-in-out;
		width: 80%;
		z-index: 20;
		overflow-y: scroll;
		
		.nav__close {
			// background-color: rgba(0, 0, 0, 0.05);
			color: #666;
			color: white;
			font-size: 1em;
			height: 50px;
			line-height: 50px;
			position: absolute;
			right: 0;
			text-align: center;
			top: 0;
			width: 50px;
			
			@media(min-width: $breakpoint-lg) {
				display: none;
			}
		}
		
		.nav__overlay {
			display: none;
			height: 100%;
			left: 80%;
			position: fixed;
			top: 0;
			width: 20%;
			
			@media(min-width: $breakpoint-lg) {
				display: none;
			}
		}
		
		&.active {
			left: 0%;
			
			.nav__overlay {
				display: block;
			}
		}
		
		ul {
			list-style: none;
			// margin-bottom: 20px;
			width: 100%;
			
			li {
				width: 100%;
				
				a {
					color: white;
					line-height: 80px;
					display: block;
					text-align: left;
					width: 100%;
					padding: 0px 25px;
					font-weight: 400;
					border-top: 1px solid #497590;
				}

				&.menu-item-has-children > a {
					&::after {
						display: inline-block;
						font-style: normal;
						font-variant: normal;
						text-rendering: auto;
						-webkit-font-smoothing: antialiased;
						font-family: "Font Awesome 5 Free";
						font-weight: 900;
						content: "\f107";
						float: right;
					}
				}

				&.menu-item-has-children.hover > a {
					&::after {
						content: "\f106";
					}
				}

				.sub-menu {
					display: none;
				}
	
				&.hover .sub-menu {
					display: block;
				}

				&.current-menu-item a {
					background-color: rgba(255,255,255,0.05);
				}
			}
			
			li.mobile-only {
				display: none;
				
				@media(min-width: $breakpoint-lg) {
					display: block;
				}
			}

			@media(min-width: $breakpoint-lg) {
				li {
					width: auto;
					a {
						width: auto;
						pad: 0px 20px;
						border-top: 0;
					}

					&.menu-item-has-children {
						position: relative;
						> a {
							&::after {
								margin-left: 5px;
							}
						}
					}

					.sub-menu {
						display: none;
						position: absolute;
						top: 100%;
						left: 0;
						background-color: white;

						a {
							white-space: nowrap;
							padding: 0px 20px;
						}
					}

					&.hover .sub-menu {
						display: table;
					}
				}
			}
		}
		
		// ul#menu-hoofdmenu {
		//   margin-left: 25px;
		
		//   @media(min-width: $breakpoint-xl) {
		//     margin-left: 50px;
		//   }
		
		//   li {
		//     display: inline-block;
		
		//     &.current-menu-item a {
		//       color: $rood;
		//       font-weight: 700;
		//     }
		
		//     a {
		//       color: $blauw;
		//       line-height: 45px;
		//       padding: 0px 10px;
		
		//       @media(min-width: $breakpoint-xxl) {
		//         padding: 0px 20px;
		//       }
		
		//       &:hover {
		//         text-decoration: underline;
		//       }
		//     }
		//   }
		// }

		@media(min-width: $breakpoint-lg) {
			display: none;
		}
	}
	
	.switch {
		color: #ccc;
		display: none;
		font-size: 12px;
		
		@media(min-width: $breakpoint-md) {
			display: block;
		}
		
		u {
			color: #666;
			font-size: inherit;
		}
	}
	
	.minicart {
		display: inline-block;
		margin: 0px 10px;
		padding: 0px 10px;
		position: relative;
		
		.minicart__overlay {
			background: none;
			height: 100%;
			left: 0;
			position: fixed;
			top: 0;
			width: 100%;
			z-index: 0;
		}
		
		@media(min-width: $breakpoint-xl) {
			margin: 0px 20px;
		}
		
		.cart-icon {
			color: $blauw;
			cursor: pointer;
			font-size: 25px;
			line-height: 25px;
			
			&:hover {
				color: darken($blauw, 10%);
			}
		}
		
		.circle {
			background: $rood;
			border-radius: 50%;
			bottom: -5px;
			color: white;
			display: block;
			font-size: 10px;
			height: 20px;
			line-height: 20px;
			position: absolute;
			right: 0px;
			text-align: center;
			width: 20px;
		}
		
		.card {
			bottom: 0;
			display: table;
			height: auto;
			// top: 50px;
			// right: -5px;
			left: 0px;
			margin-bottom: 0;
			max-height: 80%;
			max-width: $breakpoint-md;
			overflow: auto;
			padding: 25px;
			padding: 0;
			position: absolute;
			position: fixed;
			width: 100%;
			z-index: 30;

			.arrow {
				background: white;
				display: none;
				height: 15px;
				position: absolute;
				right: 20px;
				top: -7px;
				-webkit-transform: rotate(-45deg);
				transform: rotate(-45deg);
				width: 15px;
			}
			
			h3 {
				align-items: center;
				display: flex;
				padding: 15px;
				
				.close {
					color: $donkergrijs;
					cursor: pointer;
					display: block;
					font-size: 0.5em;
					line-height: inherit;
					margin-left: auto;
					text-align: right;
					text-align: center;
					width: 30px;
					
					&:hover {
						color: $antraciet;
					}
				}
			}
			
			@media(min-width: $breakpoint-md) {
				bottom: auto;
				left: auto;
				position: absolute;
				right: -7px;
				top: 45px;
				width: 400px;
				
				.arrow {
					display: block;
				}
			}
		}
		
		.minicart-empty {
			padding: 25px;
			text-align: center;
			
			h2 {
				margin-bottom: 0;
			}
		}
		
		.minicart__items {
			display: block;
			flex-direction: column;
			// max-height: 400px;
			// overflow-y: scroll
			overflow: hidden;
			
			@media(min-height: 60vh) {
				max-height: 60vh;
				overflow-y: scroll;
			}
			
			.minicart__item {
				align-items: center;
				display: flex;
				flex-direction: row;
				left: 0px;
				padding: 10px 20px;
				position: relative;
				transition: left 0.2s ease-in-out;
				
				&:nth-child(even) {
					background-color: $lichtgrijs;
				}
				
				figure {
					height: 70px;
					padding-top: 0px;
					width: 90px;
					
					img {
						object-fit: contain;
						width: 100%;
					}
				}
				
				.info {
					flex-grow: 1;
					padding: 0px 10px;
					
					.name {
						color: $blauw;
						display: block;
						font-size: 1.2em;
						font-weight: 200;
						line-height: 1;
						
						strong {
							display: block;
							font-weight: 600;
						}
					}
					
					.maanden {
						display: block;
						font-size: 0.7em;
					}
				}
				
				.aantal {
					width: 20px;
				}
				
				.remove {
					background-color: #f1f1f1;
					color: black;
					cursor: pointer;
					display: none;
					font-size: 10px;
					height: 90px;
					left: -20px;
					line-height: 90px;
					position: absolute;
					text-align: center;
					top: 0px;
					width: 20px;
					
					&:hover {
						background-color: $rood;
						color: white;
					}
				}
				
				&:hover {
					left: 20px;
					
					.remove {
						display: block;
					}
				}
			}
		}
		
		.minicart__footer {
			align-items: center;
			border-top: 1px solid #f1f1f1;
			display: flex;
			padding: 10px 20px;
			
			.aantal {
				color: $donkergrijs;
				
				strong {
					font-weight: 600;
				}
			}
			
			.price__container {
				align-self: flex-end;
				margin-left: auto;
				width: auto;
			}
		}
		
		.btn {
			display: block;
			height: auto;
			margin: 0px 10px;
			margin-bottom: 10px;
			width: calc(100% - 20px);
		}
	}
}

.header-spacer {
	display: block;
	height: 100px;
	width: 100%;
}

.bigmenu {
	background: white;
	-webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
	display: none;
	left: 0;
	padding: 50px 25px;
	padding-top: 25px;
	position: fixed;
	top: 140px;
	transition: height 2s ease-in-out;
	width: 100%;
	z-index: 21;
	
	h3 {
		color: $rood;
		font-size: 1em;
		margin-bottom: 15px;
		
		.fa {
			margin-left: 10px;
		}
	}
	
	ul {
		list-style: none;
		
		li {
			a {
				color: #666;
				display: block;
				font-size: 0.9em;
				line-height: 40px;
				text-align: left;
				width: 100%;
				
				&:hover {
					color: $rood;
				}
			}
		}
		
		.alle {
			display: none;
		}
		
		.all a {
			color: $blauw;
			font-size: 0.8em;
			font-weight: 600;
			
			.fa {
				margin-left: 10px;
				
				&.rotate {
					transform: rotate(180deg);
				}
			}
		}
	}
	
	.fa {
		font-size: 80%;
	}
}

.price__container {
	align-items: center;
	display: block;
	text-align: right;
	
	.vanaf {
		color: $blauw;
		display: block;
		font-size: 0.6em;
		font-weight: 400;
		line-height: 1;
		text-transform: uppercase;
	}
	
	.old_price {
		color: $blauw;
		font-size: 0.8em;
		font-weight: 400;
		text-decoration: line-through;
	}
	
	.price {
		color: $rood;
		display: block;
		font-size: 1em;
		font-weight: 600;
		white-space: nowrap;
		word-wrap: none;
	}
	
	.btw {
		color: #ccc;
		display: block;
		font-size: 0.6em;
		line-height: 1;
	}
}

//Vue transition - Slide
.slide-enter-active, .slide-leave-active {
	transition: all 0.3s ease-in-out;
}

.slide-enter, .slide-leave-to
	/* .slide-fade-leave-active below version 2.1.8 */
{
	transform: translateY(100%);
}

@media(min-width: $breakpoint-md) {
	.slide-enter-active, .slide-leave-active {
		transition: all 0.3s ease-in-out;
	}
	.slide-enter, .slide-leave-to
		/* .slide-fade-leave-active below version 2.1.8 */
	{
		opacity: 0;
		transform: translateY(0%);
	}
}