@use 'settings' as *;

* {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	font-family: 'Roboto', sans-serif;
	font-size: 18px;
	line-height: 1.4;
	margin: 0;
	padding: 0;
}

body {
	background: $lichtgrijs;
	opacity: 0;

	&.noscroll {
		overflow-y: hidden;
	}
}

.flexbox {
	display: flex;
	justify-content: center;
	flex-flow: row wrap;
	
	&-middle, &-center {
		align-items: center;
		justify-content: center;
	}
	
	&-start {
		justify-content: flex-start;
	}
	
	&-start-center {
		align-items: center;
		justify-content: flex-start;
	}
	
	&-column {
		flex-direction: column;
	}
}

.row-reverse {
	flex-direction: row-reverse;
}


@media(min-width: 1200px) {
	.container {
		max-width: 1200px;
	}
}

@media(min-width: 1500px) {
	.container {
		max-width: 1500px;
	}
}


section.section {
	background: #f8f9fb;
	padding: 50px 0;
	
	&--light {
		background: white;
	}
	
	// &:nth-child(even) {
	// 	background: white;
	// }
	
	.section__title {
		text-align: center;
	}
}

.text {
	&-center {
		text-align: center;
	}
	
	&-light {
		color: $donkergrijs;
	}
	
	&-small {
		font-size: 16px;
		line-height: 22px !important;
	}
}

hr {
	border: 1px solid $lichtgrijs;
}

figure {
	margin-bottom: 0;
}

.mb0 {
	margin-bottom: 0 !important;
}

.content {
	padding: 25px;
	background: white;
	margin-bottom: 15px;
	
	@media (min-width: $breakpoint-lg) {
		padding: 40px;
	}

	figure.header_img {
		margin: -25px;
		margin-bottom: 25px;
		position: relative;
		height: 0px;
		padding-top: 45%;

		@media(min-width: $breakpoint-lg) {
			margin: -40px;
			margin-bottom: 25px;
		}

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	
	img {
		width: 100%;
		height: auto;
	}
	
	&__text {
		margin-bottom: 30px;
	}
}

.mb30 {
	margin-bottom: 30px;
}

.no-margin {
	margin: 0 !important;
}

.no-padding {
	padding: 0 !important;
}

// Tarieven
.tarief_rij {
	.title {
		font-size: 1em;
		line-height: 30px;
		cursor: pointer;
		margin-bottom: 15px;

		&:hover {
			color: #666;
		}
	}
	
	.circle {
		width: 30px;
		height: 30px;
		border-radius: 50%;
		line-height: 28px;
		text-align: center;
		color: white;
		float: left;
		display: block;
		background-color: $rood;
		margin-right: 25px;

		&::after {
			content: "+";
			line-height: inherit;
			font-size: 0.8em;
		}
	}

    ul {
        list-style: none;
		margin-bottom: 25px;
        
        li a {
            font-weight: 500;
            color: $antraciet;
			text-decoration: underline;

			&:hover {
				color: $donkergrijs;
			}
        }
    }

	.text { 
		display: none;
	}

	&.hover {
		h2 {
			margin-bottom: 15px;
		}
		.circle {
			&::after {
				content: "-";
			}
		}

		.text {
			display: block;
			margin-bottom: 25px;
		}
	}
}

.wp_login_error p {
	background: none;
	border: 1px solid $rood;
	color: $rood;
	border-radius: 3px;
	margin-bottom: 15px !important;
	padding: 8px;
	font-size: 0.8em;
	display: block;
}

.card.card-news {
    width: 100%;
    border: 1px solid #ccc;
	height: calc(100% - 25px);
	// height: 100%;
	display: flex;
	flex-direction: column;
	margin-bottom: 25px;

	figure {
		width: 100%;
		height: 0px;
		position: relative;
		padding-top: 56.25%;
		background-color: #f1f1f1;
		background-image: url(../img/logo.svg);
		background-repeat: no-repeat;
		background-position: center center;
		background-size: auto 40%;

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			background-color: #f1f1f1;
		}
	}

    .text {
        padding: 25px;
		display: flex;
		flex-direction: column;
		flex: 1;
		

        h2 {
            font-size: 1.3em;
            margin-bottom: 10px;
        }
    }

	.btn {
		margin-top: auto;
		// margin-bottom: 25px;
		display: block;
	}
}

.card {
	.text {
		.entry-date {
			font-size: 0.7em;
			color: #666;
			margin-bottom: 10px;
		}
	}
}

.card.card-horizontal {
	margin-bottom: 15px;
	background-color: white;
	padding: 15px;

	figure {
		width: 100%;
		padding-top: 56.25%;
		border-radius: 5px;
		overflow: hidden;
		position: relative;

		img {
			object-fit: cover;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	.text {
		padding: 15px;

		p {
			margin-bottom: 10px;
		}

		.btn {
			margin-top: 0;
		}
	}
}

.seachform {
	width: 100%;
	max-width: 520px;
	display: flex;
	flex-direction: row;
	margin-left: auto;
	position: relative;

	input {
		width: calc(100% - 50px);
		height: 50px;
		border: 2px solid #DDDDDD;
		border-right: none;
		background-color: white;
		padding: 10px 20px;
		border-top-left-radius: 3px;
		border-bottom-left-radius: 3px;
		outline: none;
	}

	button {
		width: 50px;
		height: 50px;
		background-color: $rood;
		color: white;
		border: none;
		position: relative;
		z-index: 2;
	}

	.suggestions {
		position: absolute;
		top: calc(100% - 0px);
		width: calc(100% - 0px);
		background-color: white;
		border: 2px solid #DDDDDD;
		border-top: 1px solid #f7f7f7;
		list-style: none;
		display: none;
		z-index: 10;

		li a {
			padding: 12px;
			color: $antraciet;
			display: block;
			font-size: 0.8em;
			font-weight: normal;

			&::after {
				display: inline-block;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				content: "\f105";
				float: right;
				color: inherit;
				font-size: inherit;
			}

			&:hover {
				background-color: #f7f7f7;
			}
		}
	}
}

.file-table {
	.head {
		background-color: white;
		padding: 10px 10px;
		border-bottom: 1px solid #DDDDDD;
		
		* {
			font-size: 0.95em;
		}

		.sort {
			float: right;
			display: flex;
			flex-direction: row;

			strong {
				color: $blauw;
			}

			.fa {
				margin-left: 5px;
				color: $blauw;
			}

			form {
				display: table;
				margin-left: 5px;
			}

			select {
				border: none;
				background: none;
				height: 20px;
				padding: 0;
				color: $blauw;
				font-weight: 400;
				outline: none;
			}

			.hidden {
				display: none;
			}
		}
	}

	.sub-head {
		padding: 10px;
		padding-top: 8px;
		padding-bottom: 3px;
		color: #666;
		* {
			font-size: 0.8em;
		}
	}

	&__item {
		background-color: #EFF6FB;
		border-radius: 5px;
		padding: 10px;
		color: $blauw;
		margin: 8px 0px;
		display: block;
		font-weight: 400;
		word-wrap: break-word;

		&:hover {
			color: darken($blauw, 5%);
			background-color: darken(#EFF6FB, 5%);
		}

		h4 {
			font-weight: 500;
			color: inherit;
			font-size: 0.9em;
			word-wrap: break-word;

			@media(max-width: $breakpoint-lg) {
				padding-left: 5px;
			}
		}

		.fa, .far {
			font-size: 23px;
			width: 50px;
			height: 50px;
			line-height: 50px;
			text-align: center;
		}

		.fa-download {
			float: right;
		}

		.filename {
			font-size: 0.7em;
			position: relative;
			top: -2px;
			margin-bottom: -2px;
			display: block;

			@media(max-width: $breakpoint-lg) {
				padding-left: 5px;
			}
		}

		.date {
			font-size: 0.9em;
		}
	}

	@media(max-width: $breakpoint-lg) {
		.hide-md {
			display: none;
		}
	}
}

.single {
	.entry-date {
		font-size: 0.7em;
		color: #666;
		margin-bottom: 0px;
		position: relative;
		top: -20px;
	}

	figure.header_img {
		margin: 0px;
		margin-bottom: 25px;
		position: relative;
		height: 0px;
		padding-top: 45%;
		background-color: #f1f1f1;

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			background-color: #f1f1f1;
		}
	}
}

p#breadcrumbs {
	font-size: 0.8em;
	color: #666;
	font-weight: 400;
	margin-bottom: 0;

	span, a {
		font-size: inherit;
		font-weight: inherit;
		color: #666;
	}

	.fa {
		font-size: 90%;
		margin: 0px 3px;
	}

	a:hover {
		color: $rood;
	}
}

.search .content h1 {
	margin-bottom: 0;
}