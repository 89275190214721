@use "../partials/settings" as *;

.page-template-info-page, .page-template-page-bekeuring-opvragen {
	section.section {
		background: $lichtgrijs;
	}
}

//business online pagina's
.page-template-page-mijn-business-online {
	figure.content-image {
		height: 0;
		padding-top: 33%;
		position: relative;
		width: 100%;
		min-height: 170px;
		
		.content-image__text {
			top: 0;
			left: 0;
			position: absolute;
			z-index: 1;
			padding: 25px;
			
			@media (min-width: $breakpoint-lg) {
				padding: 40px;
			}
			
			h2 {
				font-size: 1.6em;
				
				@media (min-width: $breakpoint-lg) {
					font-size: 2em;
				}
			}
			
		}
		
		img {
			height: 100%;
			left: 50%;
			object-fit: cover;
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
			width: 100%;
		}
	}
}