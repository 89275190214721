@use "../partials/settings" as *;

.sidebar-menu {
	background: white;
	margin-bottom: 30px;
	
	&.sticky {
		@media (min-width: $breakpoint-md) {
			position: sticky;
			top: 130px;
		}
	}

	@media(max-width: $breakpoint-md) {
		display: none;
	}
	
	nav {
		ul {
			list-style: none;
			
			li {
				position: relative;
				border-bottom: 1px solid #E7E7E7;
				
				&.current-menu-item {
					background: #E7E7E7;
					
					&::after {
						content: '';
						position: absolute;
						left: 0;
						top: 0;
						height: 100%;
						width: 5px;
						background: $rood;
					}
				}
				
				&:hover {
					background: #F1F1F1;
				}
				
				&:last-child {
					border-bottom: 0;
				}
				
				a {
					padding:25px;
					display: block;
					width: 100%;
					height: 100%;
					color: $antraciet;
					font-weight: bold;
					
					&:hover {
						text-decoration: none;
					}
				}
			}
		}
	}
}