//kleuren
$rood: #C80330;
$geel: #FAB611;
$blauw: #1d4f6e;
$groen: #4C956C;
$lichtgrijs: #F9FAFB;
$donkergrijs: #888888;
$antraciet: #2C2C2C;
$success: green;
$alert: orange;
$danger: red;
$deal: #0093FF;


// fonts
@font-face {
	font-family: VWHead, sans-serif;
	src: url("../fonts/VWHeadOffice-Regular.woff") format("woff");
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: VWHead, sans-serif;
	src: url("../fonts/VWHeadOffice-Bold.woff") format("woff");
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: VWText, sans-serif;
	src: url("../fonts/VWText-Light.woff") format("woff"), url("../fonts/VWText-Light.otf") format("opentype");
	font-style: normal;
	font-weight: 200;
}

@font-face {
	font-family: 'Roboto';
	src: url('../fonts/Roboto-Regular.woff2') format('woff2'),
	url('../fonts/Roboto-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src: url('../fonts/Roboto-Light.woff2') format('woff2'),
	url('../fonts/Roboto-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src: url('../fonts/Roboto-Bold.woff2') format('woff2'),
	url('../fonts/Roboto-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

//Breakpoints
$breakpoint-xs: 452px;
$breakpoint-sm: 540px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1500px;


