@use "settings" as *;

// Custom form
fieldset {
	border: 0;
	display: flex;
	flex-flow: row wrap;
	margin-bottom: 15px;
	
	h3 {
		margin-bottom: 25px;
		width: 100%;
	}
	
	p {
		font-size: 14px;
		margin-bottom: 25px;
		width: 100%;
	}
	
	.form-input {
		position: relative;
		width: 100%;
		
		@media (min-width: $breakpoint-md) {
			width: calc(50% - 5px);
			
			&:nth-of-type(even) {
				margin-left: auto;
			}
		}
		
		&.wide {
			width: 100%;
		}
		
		label {
			background: white;
			color: $blauw;
			font-size: 14px;
			left: 15px;
			line-height: 14px;
			padding: 3px;
			position: absolute;
			top: -8px;
			
			&.error {
				color: $danger;
				font-size: 14px;
				left: 15px;
				line-height: 14px;
				bottom: 12px;
				top: auto;
			}
		}
		
		input, textarea, select {
			border: 1px solid #E7E7E7;
			margin-bottom: 20px;
			padding: 15px;
			width: 100%;
			
			&:focus {
				outline-color: $blauw;
			}
			
			&.error {
				border-color: red;
			}
		}
		
		input, select {
			height: 57px;
			line-height: 57px;
		}
	}
}

// WPForms
.wpforms-container {
	.wpforms-form {
		
		input[type=checkbox], input[type=radio] {
			margin: 0 8px 0 3px !important;
		}
		
		input.wpforms-error {
			border-color: $danger !important;
		}
		
		.wpforms-field-container {
			
			.wpforms-full-width {
				width: 100% !important;
			}
			
			.wpforms-field {
				margin: 0;
				position: relative;
				
				&.wpforms-one-half, &.wpforms-two-fourths {
					width: 100%;
					
					@media (min-width: $breakpoint-md) {
						width: calc(50% - 5px);
						
						&:not(.wpforms-first) {
							margin-left: 10px;
						}
					}
					
					input {
						max-width: 100%;
						width: 100%;
						
						&[type=checkbox], &[type=radio] {
							width: auto;
							margin: 0 6px 0 3px;
						}
					}
				}
				
				&.wpforms-one-fourth {
					width: calc(50% - 5px);
					margin-left: 2.5px;
					margin-right: 2.5px;
					
					
					@media (min-width: $breakpoint-md) {
						width: calc(25% - 7.5px);
						
						&:not(.wpforms-first) {
							margin-right: 0;
							margin-left: 10px;
						}
					}
				}
				
				&.wpforms-has-error {
					input, textarea {
						border-color: $danger;
					}
				}
				
				&-radio, &-checkbox, &-gdpr-checkbox {
					margin-bottom: 15px;
					margin-top: 15px;
					width: 100%;
					
					> label {
						left: 0 !important;
					}
					
					@media (min-width: $breakpoint-lg) {
						width: 50%;
						
						&.full-width {
							width: 100%;
						}
					}
					
					ul {
						padding-top: 15px !important;
					
						li {
							input {
								height: 13px !important;
								width: 13px !important;
							}
						}
					}
				}
				
				&-gdpr-checkbox {
					ul {
						height: auto !important;
					}
				}
				
				&.wpforms-list-inline {
					ul {
						align-items: flex-start;
						display: flex;
						flex-flow: row wrap;
					}
				}
				
				.wpforms-field-label {
					background: white;
					color: $blauw;
					font-size: 14px;
					font-weight: normal;
					left: 15px;
					line-height: 14px;
					margin: 0;
					-ms-overflow-style: none;
					overflow-x: scroll;
					padding: 2px 3px;
					position: absolute;
					scrollbar-width: none;
					top: 2px;
					white-space: nowrap;
					z-index: 1;
					
					&::-webkit-scrollbar {
						display: none;
					}
				}

				#wpforms-1007-field_13 {
					height: max-content !important;
				}
				
				.wpforms-field-label-inline {
					color: $blauw;
				}
				
				.wpforms-label-hide {
					display: none;
					
					+ ul {
						padding-top: 0 !important;
					}
				}
				
				.wpforms-field-sublabel {
					display: none;
				}
				
				.is-focused, .is-open, .is-active {
					.choices__inner, .choices__list {
						box-shadow: none;
					}
				}
				
				.choices__inner {
					padding: 0;
				}
				
				.choices__list {
					z-index: 2;
					
					&--single {
						line-height: 57px;
						padding: 15px;
					}
				}
				
				input, textarea, select, .wpforms-field-required, .wpforms-field {
					&:not([type=checkbox]), &:not([type=radio]) {
						border: 1px solid #E7E7E7;
						margin-bottom: 0;
						padding: 15px;
						width: 100%;
						
						&:not(.wpforms-error) {
							&:focus {
								border: 1px solid $blauw;
							}
						}
						
					}
				}
				
				input, select, input:not([type=radio]), input:not([type=checkbox]), .wpforms-field-required:not(textarea), .wpforms-field:not(textarea), .choices__inner {
					height: 57px;
					line-height: 57px;
					
					&[type=file] {
						line-height: 1;
					}
				}
				
				input[type=tel] {
					&.wpforms-smart-phone-field {
						padding-left: 50px;
					}
				}
				
				
			}
		}
		
		.wpforms-submit-container {
			button[type=submit] {
				background: $rood;
				border: none;
				border-radius: 3px;
				color: white;
				cursor: pointer;
				display: inline-block;
				font-size: 0.8em;
				font-weight: 400;
				height: 50px;
				letter-spacing: 1px;
				line-height: 47px;
				margin-top: 15px;
				max-width: 100%;
				min-width: 240px;
				padding: 0px 30px;
				text-align: center;
				text-decoration: none;
				text-transform: uppercase;
				transition: all .2s linear;
				white-space: nowrap;
				
				
				&:hover {
					background: darken($rood, 10%);
					color: white;
					
					a {
						text-decoration: none;
					}
				}
			}
		}
		
		
		// button overwrites for .wpforms-container .wpforms-form *
		.btn {
			background: none;
			border: 2px solid white;
			border-radius: 3px;
			color: white;
			cursor: pointer;
			display: inline-block;
			font-size: 0.8em;
			font-weight: 400;
			height: 50px;
			letter-spacing: 1px;
			line-height: 47px;
			margin-top: 15px;
			max-width: 100%;
			padding: 0px 30px;
			text-align: center;
			text-decoration: none;
			text-transform: uppercase;
			transition: all .2s linear;
			white-space: nowrap;
			
			&.btn-primary {
				background: $rood;
				border-color: $rood;
				color: white;
				
				&:hover {
					background: darken($rood, 10%);
					border-color: darken($rood, 10%);;
					color: white;
					
					a {
						text-decoration: none;
					}
				}
			}
			
			&.btn-white {
				border-color: white;
				color: white;
				
				&:hover {
					background: white;
					color: $rood;
				}
			}
			
			&.btn-secondary {
				border-color: $blauw;
				color: $blauw;
				margin-bottom: 15px;
				margin-top: 0;
				padding: 0 25px;
				text-align: center;
				
				&:hover {
					background: $blauw;
					color: white;
				}
			}
			
			&.btn-load {
				position: relative;
				
				@keyframes example {
					from {
						width: 0%;
					}
					to {
						width: calc(100% + 4px)
					}
				}
				
				&::after {
					animation-duration: 1s;
					animation-iteration-count: infinite;
					animation-name: example;
					background-color: rgba(0, 0, 0, 0.1);
					content: "";
					height: calc(100% + 4px);
					left: -2px;
					position: absolute;
					top: -2px;
					width: 0%;
				}
			}
			
			&.btn-small {
				transform: scale(.75);
				transform-origin: left center;
			}
			
			&.btn-submit {
				min-width: 300px;
			}
			
			&.btn-login {
				margin-bottom: 10px;
			}
			
			&.btn-rental {
				border-radius: 20px;
				height: 40px;
				line-height: 30px;
				margin-right: 10px;
				margin-top: 0;
				padding: 5px 25px;
				text-transform: capitalize;
			}
			
			&.btn-location {
				margin-top: 0;
				width: 250px;
			}
			
			&:hover {
				background: white;
				color: #fcfcfc;
			}
			
			.fa {
				font-size: inherit;
				line-height: inherit;
				// margin: 0 0 0 5px;
				position: relative;
			}
		}
	}
}


// login formulier
#loginform, #register-form {
	width: 100%;
	
	.login-username, .login-password, .login-email {
		margin-bottom: 0;
		position: relative;
		
		label {
			background: white;
			color: $blauw;
			font-size: 14px;
			font-weight: 400;
			left: 15px;
			line-height: 14px;
			padding: 3px;
			position: absolute;
			top: -8px;
		}
		
		input {
			border: 1px solid #E7E7E7;
			height: 57px;
			line-height: 57px;
			margin-bottom: 20px;
			padding: 15px;
			width: 100%;
			
			&:focus {
				outline-color: $blauw;
			}
		}
	}
	
	.login-submit {
		#wp-submit {
			background: none;
			background: $rood;
			border: 2px solid $rood;
			border-radius: 3px;
			color: white;
			cursor: pointer;
			display: inline-block;
			font-size: 0.8em;
			font-weight: 400;
			height: 50px;
			letter-spacing: 1px;
			line-height: 47px;
			margin-top: 15px;
			max-width: 100%;
			padding: 0px 30px;
			text-align: center;
			text-decoration: none;
			
			text-transform: uppercase;
			transition: all .2s linear;
			white-space: nowrap;
			
			&:hover {
				background: darken($rood, 10%);
				border-color: darken($rood, 10%);;
				color: white;
				
			}
		}
	}
}


input[type=text], input[type=email], input[type=tel], input[type=password], textarea {
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-attachment: fixed;
}

input[type=search] {
	-webkit-appearance: none;
	border-radius: 0;
}

.flatpickr-months .flatpickr-month {
	height: 44px;
}

@media only screen and (max-width: 600px) {
	div.wpforms-container-full .wpforms-form .wpforms-field:not(.wpforms-field-phone):not(.wpforms-field-select-style-modern) {
		overflow-x: hidden;
		overflow-y: hidden;
	}
}

.wp_login_error p {
	background: $lichtgrijs;
	border: 1px solid $danger;
	border-radius: 3px;
	margin-bottom: 25px;
	padding: 13px 15px;
}

div.wpforms-container-full .wpforms-datepicker-wrap .wpforms-field-medium+.wpforms-datepicker-clear {
	right: 15px !important;
}

.wpforms-list-inline {
	height: auto !important;
}

.flatpickr-months {
	height: 40px;
}

.bestand-error {
	display: none;
	margin-top: -20px;
	color: #c00;
	font-weight: 400;
	font-size: 0.7em;

	&.success {
		color: $success;
	}
}

#bekeuring-naam {
	&::placeholder { /* Chrome/Opera/Safari */
		font-size: .8em;
		color: $donkergrijs;
	}
	&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		font-size: .8em;
		color: $donkergrijs;
	}
	&::-moz-placeholder { /* Firefox 19+ */
		font-size: .8em;
		color: $donkergrijs;
	}
	&:-ms-input-placeholder { /* IE 10+ */
		font-size: .8em;
		color: $donkergrijs;
	}
	&:-moz-placeholder { /* Firefox 18- */
		font-size: .8em;
		color: $donkergrijs;
	}
}