@font-face {
  font-family: VWHead, sans-serif;
  src: url("../fonts/VWHeadOffice-Regular.woff") format("woff");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: VWHead, sans-serif;
  src: url("../fonts/VWHeadOffice-Bold.woff") format("woff");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: VWText, sans-serif;
  src: url("../fonts/VWText-Light.woff") format("woff"), url("../fonts/VWText-Light.otf") format("opentype");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Regular.woff2") format("woff2"), url("../fonts/Roboto-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Light.woff2") format("woff2"), url("../fonts/Roboto-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Bold.woff2") format("woff2"), url("../fonts/Roboto-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  line-height: 1.4;
  margin: 0;
  padding: 0;
}

body {
  background: #F9FAFB;
  opacity: 0;
}
body.noscroll {
  overflow-y: hidden;
}

.flexbox {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
}
.flexbox-middle, .flexbox-center {
  align-items: center;
  justify-content: center;
}
.flexbox-start {
  justify-content: flex-start;
}
.flexbox-start-center {
  align-items: center;
  justify-content: flex-start;
}
.flexbox-column {
  flex-direction: column;
}

.row-reverse {
  flex-direction: row-reverse;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
@media (min-width: 1500px) {
  .container {
    max-width: 1500px;
  }
}
section.section {
  background: #f8f9fb;
  padding: 50px 0;
}
section.section--light {
  background: white;
}
section.section .section__title {
  text-align: center;
}

.text-center {
  text-align: center;
}
.text-light {
  color: #888888;
}
.text-small {
  font-size: 16px;
  line-height: 22px !important;
}

hr {
  border: 1px solid #F9FAFB;
}

figure {
  margin-bottom: 0;
}

.mb0 {
  margin-bottom: 0 !important;
}

.content {
  padding: 25px;
  background: white;
  margin-bottom: 15px;
}
@media (min-width: 992px) {
  .content {
    padding: 40px;
  }
}
.content figure.header_img {
  margin: -25px;
  margin-bottom: 25px;
  position: relative;
  height: 0px;
  padding-top: 45%;
}
@media (min-width: 992px) {
  .content figure.header_img {
    margin: -40px;
    margin-bottom: 25px;
  }
}
.content figure.header_img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.content img {
  width: 100%;
  height: auto;
}
.content__text {
  margin-bottom: 30px;
}

.mb30 {
  margin-bottom: 30px;
}

.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.tarief_rij .title {
  font-size: 1em;
  line-height: 30px;
  cursor: pointer;
  margin-bottom: 15px;
}
.tarief_rij .title:hover {
  color: #666;
}
.tarief_rij .circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  line-height: 28px;
  text-align: center;
  color: white;
  float: left;
  display: block;
  background-color: #C80330;
  margin-right: 25px;
}
.tarief_rij .circle::after {
  content: "+";
  line-height: inherit;
  font-size: 0.8em;
}
.tarief_rij ul {
  list-style: none;
  margin-bottom: 25px;
}
.tarief_rij ul li a {
  font-weight: 500;
  color: #2C2C2C;
  text-decoration: underline;
}
.tarief_rij ul li a:hover {
  color: #888888;
}
.tarief_rij .text {
  display: none;
}
.tarief_rij.hover h2 {
  margin-bottom: 15px;
}
.tarief_rij.hover .circle::after {
  content: "-";
}
.tarief_rij.hover .text {
  display: block;
  margin-bottom: 25px;
}

.wp_login_error p {
  background: none;
  border: 1px solid #C80330;
  color: #C80330;
  border-radius: 3px;
  margin-bottom: 15px !important;
  padding: 8px;
  font-size: 0.8em;
  display: block;
}

.card.card-news {
  width: 100%;
  border: 1px solid #ccc;
  height: calc(100% - 25px);
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}
.card.card-news figure {
  width: 100%;
  height: 0px;
  position: relative;
  padding-top: 56.25%;
  background-color: #f1f1f1;
  background-image: url(../img/logo.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 40%;
}
.card.card-news figure img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #f1f1f1;
}
.card.card-news .text {
  padding: 25px;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.card.card-news .text h2 {
  font-size: 1.3em;
  margin-bottom: 10px;
}
.card.card-news .btn {
  margin-top: auto;
  display: block;
}

.card .text .entry-date {
  font-size: 0.7em;
  color: #666;
  margin-bottom: 10px;
}

.card.card-horizontal {
  margin-bottom: 15px;
  background-color: white;
  padding: 15px;
}
.card.card-horizontal figure {
  width: 100%;
  padding-top: 56.25%;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}
.card.card-horizontal figure img {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.card.card-horizontal .text {
  padding: 15px;
}
.card.card-horizontal .text p {
  margin-bottom: 10px;
}
.card.card-horizontal .text .btn {
  margin-top: 0;
}

.seachform {
  width: 100%;
  max-width: 520px;
  display: flex;
  flex-direction: row;
  margin-left: auto;
  position: relative;
}
.seachform input {
  width: calc(100% - 50px);
  height: 50px;
  border: 2px solid #DDDDDD;
  border-right: none;
  background-color: white;
  padding: 10px 20px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  outline: none;
}
.seachform button {
  width: 50px;
  height: 50px;
  background-color: #C80330;
  color: white;
  border: none;
  position: relative;
  z-index: 2;
}
.seachform .suggestions {
  position: absolute;
  top: calc(100% - 0px);
  width: calc(100% - 0px);
  background-color: white;
  border: 2px solid #DDDDDD;
  border-top: 1px solid #f7f7f7;
  list-style: none;
  display: none;
  z-index: 10;
}
.seachform .suggestions li a {
  padding: 12px;
  color: #2C2C2C;
  display: block;
  font-size: 0.8em;
  font-weight: normal;
}
.seachform .suggestions li a::after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f105";
  float: right;
  color: inherit;
  font-size: inherit;
}
.seachform .suggestions li a:hover {
  background-color: #f7f7f7;
}

.file-table .head {
  background-color: white;
  padding: 10px 10px;
  border-bottom: 1px solid #DDDDDD;
}
.file-table .head * {
  font-size: 0.95em;
}
.file-table .head .sort {
  float: right;
  display: flex;
  flex-direction: row;
}
.file-table .head .sort strong {
  color: #1d4f6e;
}
.file-table .head .sort .fa {
  margin-left: 5px;
  color: #1d4f6e;
}
.file-table .head .sort form {
  display: table;
  margin-left: 5px;
}
.file-table .head .sort select {
  border: none;
  background: none;
  height: 20px;
  padding: 0;
  color: #1d4f6e;
  font-weight: 400;
  outline: none;
}
.file-table .head .sort .hidden {
  display: none;
}
.file-table .sub-head {
  padding: 10px;
  padding-top: 8px;
  padding-bottom: 3px;
  color: #666;
}
.file-table .sub-head * {
  font-size: 0.8em;
}
.file-table__item {
  background-color: #EFF6FB;
  border-radius: 5px;
  padding: 10px;
  color: #1d4f6e;
  margin: 8px 0px;
  display: block;
  font-weight: 400;
  word-wrap: break-word;
}
.file-table__item:hover {
  color: #18415a;
  background-color: #dbebf6;
}
.file-table__item h4 {
  font-weight: 500;
  color: inherit;
  font-size: 0.9em;
  word-wrap: break-word;
}
@media (max-width: 992px) {
  .file-table__item h4 {
    padding-left: 5px;
  }
}
.file-table__item .fa, .file-table__item .far {
  font-size: 23px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}
.file-table__item .fa-download {
  float: right;
}
.file-table__item .filename {
  font-size: 0.7em;
  position: relative;
  top: -2px;
  margin-bottom: -2px;
  display: block;
}
@media (max-width: 992px) {
  .file-table__item .filename {
    padding-left: 5px;
  }
}
.file-table__item .date {
  font-size: 0.9em;
}
@media (max-width: 992px) {
  .file-table .hide-md {
    display: none;
  }
}

.single .entry-date {
  font-size: 0.7em;
  color: #666;
  margin-bottom: 0px;
  position: relative;
  top: -20px;
}
.single figure.header_img {
  margin: 0px;
  margin-bottom: 25px;
  position: relative;
  height: 0px;
  padding-top: 45%;
  background-color: #f1f1f1;
}
.single figure.header_img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #f1f1f1;
}

p#breadcrumbs {
  font-size: 0.8em;
  color: #666;
  font-weight: 400;
  margin-bottom: 0;
}
p#breadcrumbs span, p#breadcrumbs a {
  font-size: inherit;
  font-weight: inherit;
  color: #666;
}
p#breadcrumbs .fa {
  font-size: 90%;
  margin: 0px 3px;
}
p#breadcrumbs a:hover {
  color: #C80330;
}

.search .content h1 {
  margin-bottom: 0;
}

.header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 22;
}
.header .header__main {
  background: white;
  flex-direction: column;
  padding: 10px 0px;
  position: relative;
}
@media (min-width: 768px) {
  .header .header__main {
    height: 100px;
  }
}
.header .header__main .logo {
  margin-right: auto;
  max-width: 80px;
  width: 100%;
}
@media (min-width: 1500px) {
  .header .header__main .logo {
    max-width: 140px;
  }
}
.header .header__main .logo img {
  width: 100%;
}
.header .header__main .btn-menu {
  display: block;
  margin-left: 5px;
  padding: 0;
  padding-right: 25px;
  height: 53px;
  line-height: 53px;
  color: #1d4f6e;
  text-align: center;
}
.header .header__main .btn-menu .fa {
  font-size: 30px;
  line-height: 53px;
}
@media (min-width: 1200px) {
  .header .header__main .btn-menu {
    display: none;
  }
}
.header .header__main .btn {
  display: block;
  margin: 0;
}
.header .header__main .btn-reserveren {
  padding: 0px 10px;
  font-size: 0.7em;
  margin-left: 25px;
  letter-spacing: 0.5px;
  height: 45px;
  line-height: 41px;
}
@media (min-width: 992px) {
  .header .header__main .btn-reserveren {
    padding: 0px 25px;
    font-size: 0.8em;
    letter-spacing: 1px;
    margin-left: 5px;
  }
}
.header .header__main .btn-mijn {
  display: none;
  margin-right: 10px;
  padding: 0px 20px;
}
@media (min-width: 768px) {
  .header .header__main .btn-mijn {
    display: inline-block;
  }
}
.header .nav {
  align-items: center;
  background-color: #1d4f6e;
  -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  flex: 1 0 0;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  left: -100%;
  padding: 0px;
  padding-top: 50px;
  position: fixed;
  top: 0px;
  transition: left 0.2s ease-in-out;
  width: 80%;
  z-index: 20;
  overflow-y: scroll;
}
.header .nav .nav__close {
  color: #666;
  color: white;
  font-size: 1em;
  height: 50px;
  line-height: 50px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 50px;
}
@media (min-width: 992px) {
  .header .nav .nav__close {
    display: none;
  }
}
.header .nav .nav__overlay {
  display: none;
  height: 100%;
  left: 80%;
  position: fixed;
  top: 0;
  width: 20%;
}
@media (min-width: 992px) {
  .header .nav .nav__overlay {
    display: none;
  }
}
.header .nav.active {
  left: 0%;
}
.header .nav.active .nav__overlay {
  display: block;
}
.header .nav ul {
  list-style: none;
  width: 100%;
}
.header .nav ul li {
  width: 100%;
}
.header .nav ul li a {
  color: white;
  line-height: 80px;
  display: block;
  text-align: left;
  width: 100%;
  padding: 0px 25px;
  font-weight: 400;
  border-top: 1px solid #497590;
}
.header .nav ul li.menu-item-has-children > a::after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f107";
  float: right;
}
.header .nav ul li.menu-item-has-children.hover > a::after {
  content: "\f106";
}
.header .nav ul li .sub-menu {
  display: none;
}
.header .nav ul li.hover .sub-menu {
  display: block;
}
.header .nav ul li.current-menu-item a {
  background-color: rgba(255, 255, 255, 0.05);
}
.header .nav ul li.mobile-only {
  display: none;
}
@media (min-width: 992px) {
  .header .nav ul li.mobile-only {
    display: block;
  }
}
@media (min-width: 992px) {
  .header .nav ul li {
    width: auto;
  }
  .header .nav ul li a {
    width: auto;
    pad: 0px 20px;
    border-top: 0;
  }
  .header .nav ul li.menu-item-has-children {
    position: relative;
  }
  .header .nav ul li.menu-item-has-children > a::after {
    margin-left: 5px;
  }
  .header .nav ul li .sub-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
  }
  .header .nav ul li .sub-menu a {
    white-space: nowrap;
    padding: 0px 20px;
  }
  .header .nav ul li.hover .sub-menu {
    display: table;
  }
}
@media (min-width: 992px) {
  .header .nav {
    display: none;
  }
}
.header .switch {
  color: #ccc;
  display: none;
  font-size: 12px;
}
@media (min-width: 768px) {
  .header .switch {
    display: block;
  }
}
.header .switch u {
  color: #666;
  font-size: inherit;
}
.header .minicart {
  display: inline-block;
  margin: 0px 10px;
  padding: 0px 10px;
  position: relative;
}
.header .minicart .minicart__overlay {
  background: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 0;
}
@media (min-width: 1200px) {
  .header .minicart {
    margin: 0px 20px;
  }
}
.header .minicart .cart-icon {
  color: #1d4f6e;
  cursor: pointer;
  font-size: 25px;
  line-height: 25px;
}
.header .minicart .cart-icon:hover {
  color: #123246;
}
.header .minicart .circle {
  background: #C80330;
  border-radius: 50%;
  bottom: -5px;
  color: white;
  display: block;
  font-size: 10px;
  height: 20px;
  line-height: 20px;
  position: absolute;
  right: 0px;
  text-align: center;
  width: 20px;
}
.header .minicart .card {
  bottom: 0;
  display: table;
  height: auto;
  left: 0px;
  margin-bottom: 0;
  max-height: 80%;
  max-width: 768px;
  overflow: auto;
  padding: 25px;
  padding: 0;
  position: absolute;
  position: fixed;
  width: 100%;
  z-index: 30;
}
.header .minicart .card .arrow {
  background: white;
  display: none;
  height: 15px;
  position: absolute;
  right: 20px;
  top: -7px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  width: 15px;
}
.header .minicart .card h3 {
  align-items: center;
  display: flex;
  padding: 15px;
}
.header .minicart .card h3 .close {
  color: #888888;
  cursor: pointer;
  display: block;
  font-size: 0.5em;
  line-height: inherit;
  margin-left: auto;
  text-align: right;
  text-align: center;
  width: 30px;
}
.header .minicart .card h3 .close:hover {
  color: #2C2C2C;
}
@media (min-width: 768px) {
  .header .minicart .card {
    bottom: auto;
    left: auto;
    position: absolute;
    right: -7px;
    top: 45px;
    width: 400px;
  }
  .header .minicart .card .arrow {
    display: block;
  }
}
.header .minicart .minicart-empty {
  padding: 25px;
  text-align: center;
}
.header .minicart .minicart-empty h2 {
  margin-bottom: 0;
}
.header .minicart .minicart__items {
  display: block;
  flex-direction: column;
  overflow: hidden;
}
@media (min-height: 60vh) {
  .header .minicart .minicart__items {
    max-height: 60vh;
    overflow-y: scroll;
  }
}
.header .minicart .minicart__items .minicart__item {
  align-items: center;
  display: flex;
  flex-direction: row;
  left: 0px;
  padding: 10px 20px;
  position: relative;
  transition: left 0.2s ease-in-out;
}
.header .minicart .minicart__items .minicart__item:nth-child(even) {
  background-color: #F9FAFB;
}
.header .minicart .minicart__items .minicart__item figure {
  height: 70px;
  padding-top: 0px;
  width: 90px;
}
.header .minicart .minicart__items .minicart__item figure img {
  object-fit: contain;
  width: 100%;
}
.header .minicart .minicart__items .minicart__item .info {
  flex-grow: 1;
  padding: 0px 10px;
}
.header .minicart .minicart__items .minicart__item .info .name {
  color: #1d4f6e;
  display: block;
  font-size: 1.2em;
  font-weight: 200;
  line-height: 1;
}
.header .minicart .minicart__items .minicart__item .info .name strong {
  display: block;
  font-weight: 600;
}
.header .minicart .minicart__items .minicart__item .info .maanden {
  display: block;
  font-size: 0.7em;
}
.header .minicart .minicart__items .minicart__item .aantal {
  width: 20px;
}
.header .minicart .minicart__items .minicart__item .remove {
  background-color: #f1f1f1;
  color: black;
  cursor: pointer;
  display: none;
  font-size: 10px;
  height: 90px;
  left: -20px;
  line-height: 90px;
  position: absolute;
  text-align: center;
  top: 0px;
  width: 20px;
}
.header .minicart .minicart__items .minicart__item .remove:hover {
  background-color: #C80330;
  color: white;
}
.header .minicart .minicart__items .minicart__item:hover {
  left: 20px;
}
.header .minicart .minicart__items .minicart__item:hover .remove {
  display: block;
}
.header .minicart .minicart__footer {
  align-items: center;
  border-top: 1px solid #f1f1f1;
  display: flex;
  padding: 10px 20px;
}
.header .minicart .minicart__footer .aantal {
  color: #888888;
}
.header .minicart .minicart__footer .aantal strong {
  font-weight: 600;
}
.header .minicart .minicart__footer .price__container {
  align-self: flex-end;
  margin-left: auto;
  width: auto;
}
.header .minicart .btn {
  display: block;
  height: auto;
  margin: 0px 10px;
  margin-bottom: 10px;
  width: calc(100% - 20px);
}

.header-spacer {
  display: block;
  height: 100px;
  width: 100%;
}

.bigmenu {
  background: white;
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
  display: none;
  left: 0;
  padding: 50px 25px;
  padding-top: 25px;
  position: fixed;
  top: 140px;
  transition: height 2s ease-in-out;
  width: 100%;
  z-index: 21;
}
.bigmenu h3 {
  color: #C80330;
  font-size: 1em;
  margin-bottom: 15px;
}
.bigmenu h3 .fa {
  margin-left: 10px;
}
.bigmenu ul {
  list-style: none;
}
.bigmenu ul li a {
  color: #666;
  display: block;
  font-size: 0.9em;
  line-height: 40px;
  text-align: left;
  width: 100%;
}
.bigmenu ul li a:hover {
  color: #C80330;
}
.bigmenu ul .alle {
  display: none;
}
.bigmenu ul .all a {
  color: #1d4f6e;
  font-size: 0.8em;
  font-weight: 600;
}
.bigmenu ul .all a .fa {
  margin-left: 10px;
}
.bigmenu ul .all a .fa.rotate {
  transform: rotate(180deg);
}
.bigmenu .fa {
  font-size: 80%;
}

.price__container {
  align-items: center;
  display: block;
  text-align: right;
}
.price__container .vanaf {
  color: #1d4f6e;
  display: block;
  font-size: 0.6em;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
}
.price__container .old_price {
  color: #1d4f6e;
  font-size: 0.8em;
  font-weight: 400;
  text-decoration: line-through;
}
.price__container .price {
  color: #C80330;
  display: block;
  font-size: 1em;
  font-weight: 600;
  white-space: nowrap;
  word-wrap: none;
}
.price__container .btw {
  color: #ccc;
  display: block;
  font-size: 0.6em;
  line-height: 1;
}

.slide-enter-active, .slide-leave-active {
  transition: all 0.3s ease-in-out;
}

.slide-enter, .slide-leave-to {
  transform: translateY(100%);
}

@media (min-width: 768px) {
  .slide-enter-active, .slide-leave-active {
    transition: all 0.3s ease-in-out;
  }
  .slide-enter, .slide-leave-to {
    opacity: 0;
    transform: translateY(0%);
  }
}
footer.footer {
  background-color: #222222;
  color: white;
  line-height: 1.3;
  padding: 50px 0 25px;
}
footer.footer h3 {
  color: white;
  font-size: 1em;
}
footer.footer a {
  color: #ccc;
  font-size: 0.9em;
}
footer.footer a:hover {
  color: white;
}
footer.footer .logo {
  max-width: 140px;
  width: 100%;
}
footer.footer .footer-item {
  display: flex;
  flex-direction: column;
  height: calc(100% - 25px);
  margin-bottom: 25px;
  width: 100%;
}
footer.footer .footer-item h3 {
  margin-bottom: 5px;
  cursor: pointer;
}
footer.footer .footer-item h3::after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f107";
  float: right;
  color: #666;
}
footer.footer .footer-item .text {
  display: none;
}
footer.footer .footer-item.hover .text {
  display: block;
}
footer.footer .footer-item.hover h3::after {
  content: "\f106";
}
@media (min-width: 992px) {
  footer.footer .footer-item {
    margin-bottom: 25px;
  }
  footer.footer .footer-item h3 {
    line-height: 1;
    margin-bottom: 25px;
  }
  footer.footer .footer-item h3::after {
    display: none;
  }
  footer.footer .footer-item .text {
    display: block;
  }
}
footer.footer .footer-item p {
  margin-bottom: 0;
}
footer.footer .footer-item__noheight {
  height: auto;
}
footer.footer .footer-item .bovag_container {
  display: block;
  margin-top: auto;
  margin-bottom: 25px;
}
footer.footer .footer-item .bovag_container a {
  display: block;
  font-size: 0.9em;
  margin: 10px 0;
}
footer.footer .footer-item .bovag_container img {
  display: block;
  margin-top: 30px;
  width: 90px;
}
footer.footer .footer-item .bovag_container small {
  display: block;
  font-size: 0.6em;
  line-height: 1.05;
}
footer.footer .footer-item ul {
  list-style: none;
}
footer.footer .footer-item ul li {
  line-height: 2;
}
footer.footer .footer-item__socials {
  display: table;
  width: 100%;
}
footer.footer .footer-item__socials .social-icon {
  display: table;
  float: left;
}
footer.footer .footer-item__socials .social-icon .fab {
  display: block;
  float: left;
  font-size: 25px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  width: 40px;
}
footer.footer .footer-item__socials .social-icon .fab:hover {
  color: #1d4f6e;
}
footer.footer .footer-item__vragen {
  display: table;
  width: 100%;
}
footer.footer .copyright {
  color: #848484;
  display: table;
  font-size: 0.7em;
  font-weight: 200;
  margin: 0 auto;
  padding: 25px;
}
footer.footer .copyright a {
  color: inherit;
  cursor: pointer;
  font-weight: inherit;
}
footer.footer .copyright a:hover {
  color: #333;
}

.footer-spacer {
  height: 70px;
  width: 100%;
}

.btn {
  background: none;
  border: 2px solid white;
  border-radius: 3px;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: 0.8em;
  font-weight: 400;
  height: 50px;
  letter-spacing: 1px;
  line-height: 47px;
  margin-top: 15px;
  max-width: 100%;
  padding: 0px 30px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.2s linear;
  white-space: nowrap;
  -webkit-appearance: none;
}
.btn.btn-primary {
  background: #C80330;
  border-color: #C80330;
  color: white;
}
.btn.btn-primary:hover {
  background: #960224;
  border-color: #960224;
  color: white;
}
.btn.btn-primary:hover a {
  text-decoration: none;
}
.btn.btn-white {
  border-color: white;
  color: white;
}
.btn.btn-white:hover {
  background: white;
  color: #C80330;
}
.btn.btn-secondary {
  border-color: #1d4f6e;
  color: #1d4f6e;
  padding: 0 25px;
  text-align: center;
}
.btn.btn-secondary:hover {
  background: #1d4f6e;
  color: white;
}
.btn.btn-secondary__red {
  border-color: #C80330;
  color: #C80330;
}
.btn.btn-secondary__red:hover {
  background: #C80330;
}
.btn.btn-load {
  position: relative;
}
@keyframes example {
  from {
    width: 0%;
  }
  to {
    width: calc(100% + 4px);
  }
}
.btn.btn-load::after {
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: example;
  background-color: rgba(0, 0, 0, 0.1);
  content: "";
  height: calc(100% + 4px);
  left: -2px;
  position: absolute;
  top: -2px;
  width: 0%;
}
.btn.btn-small {
  transform: scale(0.75);
  transform-origin: left center;
  margin-top: 0;
}
.btn.btn-submit {
  min-width: 300px;
}
.btn.btn-login {
  margin-bottom: 10px;
}
.btn.btn-rental {
  margin-right: 10px;
  margin-top: 0;
  padding: 5px 25px;
  height: 40px;
  line-height: 30px;
  border-radius: 20px;
  text-transform: capitalize;
}
.btn.btn-location {
  margin-top: 0;
  width: 250px;
}
.btn:hover {
  background: white;
  color: #fcfcfc;
}
.btn .fa {
  font-size: inherit;
  line-height: inherit;
  position: relative;
}

h1 {
  color: #2C2C2C;
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 25px;
}

h2 {
  color: #2C2C2C;
  font-size: 1.6em;
  font-weight: bold;
  margin-bottom: 25px;
}

h3 {
  color: #2C2C2C;
  font-size: 1em;
  font-weight: 600;
  margin-bottom: 0;
}

h4 {
  color: #2C2C2C;
  font-size: 1em;
  font-weight: 500;
}

span {
  font-size: inherit;
  font-weight: inherit;
}

p {
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5;
  margin-bottom: 25px;
}
p:last-child {
  margin-bottom: 0;
}

ol li {
  list-style-position: inside;
  color: inherit;
  line-height: inherit;
  font-size: inherit;
  margin-bottom: 15px;
  font-weight: 300;
}

a {
  background-color: transparent;
  color: #C80330;
  cursor: pointer;
  font-size: 1em;
  font-weight: 300;
  text-decoration: none;
}
a:hover {
  color: #7d021e;
}
a.small {
  font-size: 0.8em;
  text-decoration: underline;
}

.black {
  color: black !important;
}

.white {
  color: white !important;
}

.bold {
  font-weight: bold !important;
}

.center {
  text-align: center !important;
}

.left {
  text-align: left !important;
}

.right {
  text-align: right !important;
}

.success {
  color: green;
}

.alert {
  color: orange;
}

.danger {
  color: red;
}

.grey {
  color: #888888;
}

.rood {
  color: #C80330 !important;
}

.uppercase {
  text-transform: uppercase;
}

.small {
  font-size: smaller;
}

.slick-slider .slick-list .slick-track .slick-slide {
  height: 100%;
}
.slick-slider ul.slick-dots {
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  padding: 1rem 0;
  width: 100%;
}
.slick-slider ul.slick-dots li {
  margin: 0 0.25rem;
}
.slick-slider ul.slick-dots li button {
  background-color: rgba(249, 250, 251, 0.75);
  border: none;
  border-radius: 100%;
  display: block;
  height: 1rem;
  padding: 0;
  text-indent: -9999px;
  width: 1rem;
}
.slick-slider ul.slick-dots li button:focus, .slick-slider ul.slick-dots li button:active {
  border: 0;
  outline: 0;
}
.merken .slider .slick-list {
  padding: 0 10% 0 0;
}
@media (min-width: 452px) {
  .merken .slider .slick-list {
    padding: 0 20% 0 0;
  }
}
@media (min-width: 992px) {
  .merken .slider .slick-list {
    margin: 0;
    padding: 0;
  }
}
.merken .slider .rechtsgebied__col {
  margin-bottom: 0;
  padding: 0 5px;
}
@media (min-width: 540px) {
  .merken .slider .rechtsgebied__col {
    padding: 0 15px;
  }
}
.merken .slider-alt .slick-list {
  padding: 0 10% 0 0;
}
@media (min-width: 452px) {
  .merken .slider-alt .slick-list {
    padding: 0 20% 0 0;
  }
}
@media (min-width: 992px) {
  .merken .slider-alt .slick-list {
    margin: 0;
    padding: 0;
  }
}
.merken .slider-alt .rechtsgebied__col {
  margin-bottom: 0;
  padding: 0 5px;
}
@media (min-width: 540px) {
  .merken .slider-alt .rechtsgebied__col {
    padding: 0 15px;
  }
}
.merken .slider-alt .slider-arrow {
  cursor: pointer;
  font-size: 38px;
  font-weight: bold;
  height: 38px;
  line-height: 38px;
  position: absolute;
  top: calc(50% - 19px);
  z-index: 10;
}
.merken .slider-alt .slider-prev {
  left: 5px;
}
@media (min-width: 768px) {
  .merken .slider-alt .slider-prev {
    left: -15px;
  }
}
.merken .slider-alt .slider-next {
  right: 5px;
}
@media (min-width: 768px) {
  .merken .slider-alt .slider-next {
    right: -15px;
  }
}

fieldset {
  border: 0;
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 15px;
}
fieldset h3 {
  margin-bottom: 25px;
  width: 100%;
}
fieldset p {
  font-size: 14px;
  margin-bottom: 25px;
  width: 100%;
}
fieldset .form-input {
  position: relative;
  width: 100%;
}
@media (min-width: 768px) {
  fieldset .form-input {
    width: calc(50% - 5px);
  }
  fieldset .form-input:nth-of-type(even) {
    margin-left: auto;
  }
}
fieldset .form-input.wide {
  width: 100%;
}
fieldset .form-input label {
  background: white;
  color: #1d4f6e;
  font-size: 14px;
  left: 15px;
  line-height: 14px;
  padding: 3px;
  position: absolute;
  top: -8px;
}
fieldset .form-input label.error {
  color: red;
  font-size: 14px;
  left: 15px;
  line-height: 14px;
  bottom: 12px;
  top: auto;
}
fieldset .form-input input, fieldset .form-input textarea, fieldset .form-input select {
  border: 1px solid #E7E7E7;
  margin-bottom: 20px;
  padding: 15px;
  width: 100%;
}
fieldset .form-input input:focus, fieldset .form-input textarea:focus, fieldset .form-input select:focus {
  outline-color: #1d4f6e;
}
fieldset .form-input input.error, fieldset .form-input textarea.error, fieldset .form-input select.error {
  border-color: red;
}
fieldset .form-input input, fieldset .form-input select {
  height: 57px;
  line-height: 57px;
}

.wpforms-container .wpforms-form input[type=checkbox], .wpforms-container .wpforms-form input[type=radio] {
  margin: 0 8px 0 3px !important;
}
.wpforms-container .wpforms-form input.wpforms-error {
  border-color: red !important;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-full-width {
  width: 100% !important;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field {
  margin: 0;
  position: relative;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field.wpforms-one-half, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field.wpforms-two-fourths {
  width: 100%;
}
@media (min-width: 768px) {
  .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field.wpforms-one-half, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field.wpforms-two-fourths {
    width: calc(50% - 5px);
  }
  .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field.wpforms-one-half:not(.wpforms-first), .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field.wpforms-two-fourths:not(.wpforms-first) {
    margin-left: 10px;
  }
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field.wpforms-one-half input, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field.wpforms-two-fourths input {
  max-width: 100%;
  width: 100%;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field.wpforms-one-half input[type=checkbox], .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field.wpforms-one-half input[type=radio], .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field.wpforms-two-fourths input[type=checkbox], .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field.wpforms-two-fourths input[type=radio] {
  width: auto;
  margin: 0 6px 0 3px;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field.wpforms-one-fourth {
  width: calc(50% - 5px);
  margin-left: 2.5px;
  margin-right: 2.5px;
}
@media (min-width: 768px) {
  .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field.wpforms-one-fourth {
    width: calc(25% - 7.5px);
  }
  .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field.wpforms-one-fourth:not(.wpforms-first) {
    margin-right: 0;
    margin-left: 10px;
  }
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field.wpforms-has-error input, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field.wpforms-has-error textarea {
  border-color: red;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field-radio, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field-checkbox, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field-gdpr-checkbox {
  margin-bottom: 15px;
  margin-top: 15px;
  width: 100%;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field-radio > label, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field-checkbox > label, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field-gdpr-checkbox > label {
  left: 0 !important;
}
@media (min-width: 992px) {
  .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field-radio, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field-checkbox, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field-gdpr-checkbox {
    width: 50%;
  }
  .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field-radio.full-width, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field-checkbox.full-width, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field-gdpr-checkbox.full-width {
    width: 100%;
  }
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field-radio ul, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field-checkbox ul, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field-gdpr-checkbox ul {
  padding-top: 15px !important;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field-radio ul li input, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field-checkbox ul li input, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field-gdpr-checkbox ul li input {
  height: 13px !important;
  width: 13px !important;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field-gdpr-checkbox ul {
  height: auto !important;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field.wpforms-list-inline ul {
  align-items: flex-start;
  display: flex;
  flex-flow: row wrap;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .wpforms-field-label {
  background: white;
  color: #1d4f6e;
  font-size: 14px;
  font-weight: normal;
  left: 15px;
  line-height: 14px;
  margin: 0;
  -ms-overflow-style: none;
  overflow-x: scroll;
  padding: 2px 3px;
  position: absolute;
  scrollbar-width: none;
  top: 2px;
  white-space: nowrap;
  z-index: 1;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .wpforms-field-label::-webkit-scrollbar {
  display: none;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field #wpforms-1007-field_13 {
  height: max-content !important;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .wpforms-field-label-inline {
  color: #1d4f6e;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .wpforms-label-hide {
  display: none;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .wpforms-label-hide + ul {
  padding-top: 0 !important;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .wpforms-field-sublabel {
  display: none;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .is-focused .choices__inner, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .is-focused .choices__list, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .is-open .choices__inner, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .is-open .choices__list, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .is-active .choices__inner, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .is-active .choices__list {
  box-shadow: none;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .choices__inner {
  padding: 0;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .choices__list {
  z-index: 2;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .choices__list--single {
  line-height: 57px;
  padding: 15px;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field input:not([type=checkbox]), .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field input:not([type=radio]), .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field textarea:not([type=checkbox]), .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field textarea:not([type=radio]), .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field select:not([type=checkbox]), .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field select:not([type=radio]), .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .wpforms-field-required:not([type=checkbox]), .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .wpforms-field-required:not([type=radio]), .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .wpforms-field:not([type=checkbox]), .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .wpforms-field:not([type=radio]) {
  border: 1px solid #E7E7E7;
  margin-bottom: 0;
  padding: 15px;
  width: 100%;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field input:not([type=checkbox]):not(.wpforms-error):focus, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field input:not([type=radio]):not(.wpforms-error):focus, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field textarea:not([type=checkbox]):not(.wpforms-error):focus, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field textarea:not([type=radio]):not(.wpforms-error):focus, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field select:not([type=checkbox]):not(.wpforms-error):focus, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field select:not([type=radio]):not(.wpforms-error):focus, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .wpforms-field-required:not([type=checkbox]):not(.wpforms-error):focus, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .wpforms-field-required:not([type=radio]):not(.wpforms-error):focus, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .wpforms-field:not([type=checkbox]):not(.wpforms-error):focus, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .wpforms-field:not([type=radio]):not(.wpforms-error):focus {
  border: 1px solid #1d4f6e;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field input, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field select, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field input:not([type=radio]), .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field input:not([type=checkbox]), .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .wpforms-field-required:not(textarea), .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .wpforms-field:not(textarea), .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .choices__inner {
  height: 57px;
  line-height: 57px;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field input[type=file], .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field select[type=file], .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field input:not([type=radio])[type=file], .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field input:not([type=checkbox])[type=file], .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .wpforms-field-required:not(textarea)[type=file], .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .wpforms-field:not(textarea)[type=file], .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .choices__inner[type=file] {
  line-height: 1;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field input[type=tel].wpforms-smart-phone-field {
  padding-left: 50px;
}
.wpforms-container .wpforms-form .wpforms-submit-container button[type=submit] {
  background: #C80330;
  border: none;
  border-radius: 3px;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: 0.8em;
  font-weight: 400;
  height: 50px;
  letter-spacing: 1px;
  line-height: 47px;
  margin-top: 15px;
  max-width: 100%;
  min-width: 240px;
  padding: 0px 30px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.2s linear;
  white-space: nowrap;
}
.wpforms-container .wpforms-form .wpforms-submit-container button[type=submit]:hover {
  background: #960224;
  color: white;
}
.wpforms-container .wpforms-form .wpforms-submit-container button[type=submit]:hover a {
  text-decoration: none;
}
.wpforms-container .wpforms-form .btn {
  background: none;
  border: 2px solid white;
  border-radius: 3px;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: 0.8em;
  font-weight: 400;
  height: 50px;
  letter-spacing: 1px;
  line-height: 47px;
  margin-top: 15px;
  max-width: 100%;
  padding: 0px 30px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.2s linear;
  white-space: nowrap;
}
.wpforms-container .wpforms-form .btn.btn-primary {
  background: #C80330;
  border-color: #C80330;
  color: white;
}
.wpforms-container .wpforms-form .btn.btn-primary:hover {
  background: #960224;
  border-color: #960224;
  color: white;
}
.wpforms-container .wpforms-form .btn.btn-primary:hover a {
  text-decoration: none;
}
.wpforms-container .wpforms-form .btn.btn-white {
  border-color: white;
  color: white;
}
.wpforms-container .wpforms-form .btn.btn-white:hover {
  background: white;
  color: #C80330;
}
.wpforms-container .wpforms-form .btn.btn-secondary {
  border-color: #1d4f6e;
  color: #1d4f6e;
  margin-bottom: 15px;
  margin-top: 0;
  padding: 0 25px;
  text-align: center;
}
.wpforms-container .wpforms-form .btn.btn-secondary:hover {
  background: #1d4f6e;
  color: white;
}
.wpforms-container .wpforms-form .btn.btn-load {
  position: relative;
}
@keyframes example {
  from {
    width: 0%;
  }
  to {
    width: calc(100% + 4px);
  }
}
.wpforms-container .wpforms-form .btn.btn-load::after {
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: example;
  background-color: rgba(0, 0, 0, 0.1);
  content: "";
  height: calc(100% + 4px);
  left: -2px;
  position: absolute;
  top: -2px;
  width: 0%;
}
.wpforms-container .wpforms-form .btn.btn-small {
  transform: scale(0.75);
  transform-origin: left center;
}
.wpforms-container .wpforms-form .btn.btn-submit {
  min-width: 300px;
}
.wpforms-container .wpforms-form .btn.btn-login {
  margin-bottom: 10px;
}
.wpforms-container .wpforms-form .btn.btn-rental {
  border-radius: 20px;
  height: 40px;
  line-height: 30px;
  margin-right: 10px;
  margin-top: 0;
  padding: 5px 25px;
  text-transform: capitalize;
}
.wpforms-container .wpforms-form .btn.btn-location {
  margin-top: 0;
  width: 250px;
}
.wpforms-container .wpforms-form .btn:hover {
  background: white;
  color: #fcfcfc;
}
.wpforms-container .wpforms-form .btn .fa {
  font-size: inherit;
  line-height: inherit;
  position: relative;
}

#loginform, #register-form {
  width: 100%;
}
#loginform .login-username, #loginform .login-password, #loginform .login-email, #register-form .login-username, #register-form .login-password, #register-form .login-email {
  margin-bottom: 0;
  position: relative;
}
#loginform .login-username label, #loginform .login-password label, #loginform .login-email label, #register-form .login-username label, #register-form .login-password label, #register-form .login-email label {
  background: white;
  color: #1d4f6e;
  font-size: 14px;
  font-weight: 400;
  left: 15px;
  line-height: 14px;
  padding: 3px;
  position: absolute;
  top: -8px;
}
#loginform .login-username input, #loginform .login-password input, #loginform .login-email input, #register-form .login-username input, #register-form .login-password input, #register-form .login-email input {
  border: 1px solid #E7E7E7;
  height: 57px;
  line-height: 57px;
  margin-bottom: 20px;
  padding: 15px;
  width: 100%;
}
#loginform .login-username input:focus, #loginform .login-password input:focus, #loginform .login-email input:focus, #register-form .login-username input:focus, #register-form .login-password input:focus, #register-form .login-email input:focus {
  outline-color: #1d4f6e;
}
#loginform .login-submit #wp-submit, #register-form .login-submit #wp-submit {
  background: none;
  background: #C80330;
  border: 2px solid #C80330;
  border-radius: 3px;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: 0.8em;
  font-weight: 400;
  height: 50px;
  letter-spacing: 1px;
  line-height: 47px;
  margin-top: 15px;
  max-width: 100%;
  padding: 0px 30px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.2s linear;
  white-space: nowrap;
}
#loginform .login-submit #wp-submit:hover, #register-form .login-submit #wp-submit:hover {
  background: #960224;
  border-color: #960224;
  color: white;
}

input[type=text], input[type=email], input[type=tel], input[type=password], textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-attachment: fixed;
}

input[type=search] {
  -webkit-appearance: none;
  border-radius: 0;
}

.flatpickr-months .flatpickr-month {
  height: 44px;
}

@media only screen and (max-width: 600px) {
  div.wpforms-container-full .wpforms-form .wpforms-field:not(.wpforms-field-phone):not(.wpforms-field-select-style-modern) {
    overflow-x: hidden;
    overflow-y: hidden;
  }
}
.wp_login_error p {
  background: #F9FAFB;
  border: 1px solid red;
  border-radius: 3px;
  margin-bottom: 25px;
  padding: 13px 15px;
}

div.wpforms-container-full .wpforms-datepicker-wrap .wpforms-field-medium + .wpforms-datepicker-clear {
  right: 15px !important;
}

.wpforms-list-inline {
  height: auto !important;
}

.flatpickr-months {
  height: 40px;
}

.bestand-error {
  display: none;
  margin-top: -20px;
  color: #c00;
  font-weight: 400;
  font-size: 0.7em;
}
.bestand-error.success {
  color: green;
}

#bekeuring-naam::placeholder { /* Chrome/Opera/Safari */
  font-size: 0.8em;
  color: #888888;
}
#bekeuring-naam::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-size: 0.8em;
  color: #888888;
}
#bekeuring-naam::-moz-placeholder { /* Firefox 19+ */
  font-size: 0.8em;
  color: #888888;
}
#bekeuring-naam:-ms-input-placeholder { /* IE 10+ */
  font-size: 0.8em;
  color: #888888;
}
#bekeuring-naam:-moz-placeholder { /* Firefox 18- */
  font-size: 0.8em;
  color: #888888;
}

#webshop-formulier fieldset:first-of-type {
  display: block;
}
#webshop-formulier fieldset.jouw-gegevens {
  display: none;
}

.producten {
  list-style: none;
  margin-bottom: 30px;
}
.producten .product {
  position: relative;
}
.producten .product:last-child > label {
  border-bottom: 0;
}
.producten .product label.label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.producten .product .product__inner {
  align-items: center;
  border-bottom: 2px solid #f8f9fb;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 0px;
  transition: background 0.2s ease-out;
  width: 100%;
  position: relative;
}
@media (min-width: 992px) {
  .producten .product .product__inner {
    flex-direction: row;
    padding: 20px 40px 20px 50px;
  }
}
.producten .product .product__inner:hover {
  background: #EFF6FB;
  transition: background 0.2s ease-out;
}
.producten .product .product__inner .product__thumbnail {
  height: 150px;
  object-fit: contain;
  width: 150px;
  background-color: #f1f1f1;
  background-image: url(../img/logo.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% auto;
  position: relative;
}
.producten .product .product__inner .product__thumbnail img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #f1f1f1;
}
.producten .product .checkmark {
  background: #fff;
  border: 1px solid black;
  border-radius: 5px;
  color: green;
  display: block;
  height: 25px;
  left: 10px;
  line-height: 50px;
  padding: 2px;
  position: absolute;
  top: 95px;
  transform: translateY(-50%);
  transition: opacity 0.2s ease-out;
  width: 25px;
}
@media (min-width: 992px) {
  .producten .product .checkmark {
    left: 12.5px;
    top: calc(50% - 12.5px);
    transform: translateY(0);
  }
}
.producten .product .checkmark i {
  display: none;
  color: #1d4f6e;
}
.producten .product .checkmark i::before {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.producten .product input[type=checkbox] {
  display: none;
}
.producten .product input[type=checkbox]:checked ~ .label {
  display: none;
}
.producten .product input[type=checkbox]:checked ~ .product__inner {
  background-color: #dbebf6;
  border-color: white;
  transition: background 0.2s ease-out;
}
.producten .product input[type=checkbox]:checked ~ .product__inner .checkmark i {
  display: block;
}
.producten .product input[type=checkbox]:checked ~ .product__inner .product__aantal-container, .producten .product input[type=checkbox]:checked ~ .product__inner .checkmark {
  display: flex;
}
.producten .product .product__aantal-container {
  display: none;
  text-align: center;
}
@media (min-width: 992px) {
  .producten .product .product__aantal-container {
    margin-right: 50px;
    text-align: left;
  }
}
.producten .product input[type=number] {
  padding: 5px 15px;
  text-align: center;
  width: 100px;
  border-radius: 5px;
  border: 1px solid #1d4f6e;
}
@media (min-width: 992px) {
  .producten .product input[type=number] {
    text-align: left;
  }
}
.producten .product .product__titel {
  margin: 10px 15px;
}
@media (min-width: 992px) {
  .producten .product .product__titel {
    max-width: 250px;
  }
}
@media (min-width: 1200px) {
  .producten .product .product__titel {
    margin: 0 auto 0 30px;
    max-width: 500px;
  }
}
.producten .product .product__prijs-container {
  margin: 10px 0;
}
@media (min-width: 992px) {
  .producten .product .product__prijs-container {
    margin: 0;
  }
}
.producten .product .product__prijs-container > span {
  text-align: center;
}
@media (min-width: 992px) {
  .producten .product .product__prijs-container > span {
    text-align: right;
  }
}

.totaalprijs {
  display: none;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 15px;
}
@media (min-width: 992px) {
  .totaalprijs {
    justify-content: flex-end;
    margin-right: 40px;
  }
}
.totaalprijs > span {
  text-align: center;
}
@media (min-width: 992px) {
  .totaalprijs > span {
    text-align: right;
  }
}
.totaalprijs input {
  align-self: center;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  border: 0;
  color: black;
  text-align: center;
}
@media (min-width: 992px) {
  .totaalprijs input {
    align-self: flex-end;
    text-align: right;
  }
}

section.section.intro {
  max-height: 550px;
  min-height: 40vh;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
section.section.intro--small {
  max-height: 330px;
  min-height: 30vh;
}
section.section.intro--micro {
  max-height: 150px;
  min-height: 150px;
  background: #EFF6FB;
  background-image: url(../img/header.jpg);
  background-position: right -1px center;
  background-repeat: no-repeat;
}
section.section.intro--micro h1 {
  color: #1d4f6e;
}
section.section.intro .container, section.section.intro .row {
  height: 100%;
}
section.section.intro .bg {
  height: 100%;
  left: 50%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
section.section.intro .gradient {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.2) 100%);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
@media (min-width: 992px) {
  section.section.intro .gradient {
    background: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
    width: 66%;
  }
}
section.section.intro .text {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}
section.section.intro .text h1 {
  margin-bottom: 15px;
}
@media (min-width: 992px) {
  section.section.intro .text h1 {
    font-size: 65px;
    line-height: 70px;
  }
}
@media (min-width: 540px) {
  section.section.intro .text p {
    width: 66%;
  }
}
@media (min-width: 992px) {
  section.section.intro .text p {
    width: 50%;
  }
}
section.section.intro .text .btn {
  margin-top: 0;
}
@media (min-width: 768px) {
  section.section.intro .text .btn {
    align-self: flex-start;
  }
}

.usp {
  background: white;
  padding: 0px 0px;
  position: relative;
  width: 100%;
  z-index: 2;
}
.usp ul {
  display: table;
  height: 50px;
  list-style: none;
  margin: 0 auto;
  position: relative;
  width: 100%;
}
.usp ul li {
  color: #666;
  display: none;
  font-size: 0.87em;
  font-weight: 300;
  left: 0;
  line-height: 1;
  padding: 17.5px 0px;
  position: absolute;
  text-align: center;
  width: 100%;
}
.usp ul li .fas {
  margin-right: 5px;
  color: #4C956C;
  font-size: inherit;
}
@media (min-width: 1200px) {
  .usp ul {
    width: auto;
    margin: 0;
    justify-content: space-between;
    display: flex;
  }
  .usp ul li {
    display: block;
    float: left;
    position: relative;
    text-align: center;
    width: auto;
  }
}

section.text-image .text-image__text {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 30px 0;
}
@media (min-width: 768px) {
  section.text-image .text-image__text {
    padding: 0;
  }
}
section.text-image .text-image__text--has-image {
  padding: 30px 10px;
  position: relative;
}
section.text-image .text-image__text--has-image h2 {
  margin-top: 30px;
}
@media (min-width: 992px) {
  section.text-image .text-image__text--has-image h2 {
    margin-top: 0;
  }
}
@media (min-width: 992px) {
  section.text-image .text-image__text--has-image {
    padding: 45px;
  }
}
@media (min-width: 1200px) {
  section.text-image .text-image__text--has-image {
    box-shadow: 0 2px 33px 7px #EBF0FB;
    padding: 90px;
  }
}
section.text-image .text-image__text--has-image figure {
  height: 0;
  width: 100%;
  padding-top: 56.25%;
  overflow: hidden;
  position: relative;
}
section.text-image .text-image__text--has-image figure.hover-img {
  display: block;
  z-index: 1;
  transition: opacity 0.2s ease-out;
}
@media (min-width: 992px) {
  section.text-image .text-image__text--has-image figure.hover-img {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
}
section.text-image .text-image__text--has-image figure img {
  height: 100%;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  position: absolute;
}
section.text-image .text-image__text h2 {
  color: #C80330;
  text-align: left;
}
section.text-image .no-padding {
  padding: 0;
}
@media (min-width: 768px) {
  section.text-image .no-padding {
    padding: 0 15px;
  }
}
section.text-image figure {
  height: 100%;
  width: 100%;
}
section.text-image figure img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
@media (min-width: 768px) {
  section.text-image .btn {
    align-self: flex-start;
  }
}
section.text-image .order-1 {
  order: 1;
}
@media (min-width: 768px) {
  section.text-image .order-1 {
    order: initial;
  }
}

.merken h2 {
  color: #C80330;
  margin-bottom: 60px;
}

.card-cars {
  display: flex;
  flex-wrap: wrap;
}
.card-cars__card {
  align-items: center;
  border: 1px solid #E7E7E7;
  border-radius: 3px;
  display: flex;
  height: 80px;
  justify-content: center;
  margin: 5px;
  position: relative;
  transform-origin: bottom center;
  transition: 0.15s ease-out;
  width: calc(50% - 5px);
}
.card-cars__card:hover {
  box-shadow: 0 2px 15px 6px rgba(151, 160, 178, 0.2);
  transition: 0.15s ease-in;
  z-index: 1;
}
@media (min-width: 768px) {
  .card-cars__card:hover {
    box-shadow: 0 2px 30px 6px rgba(151, 160, 178, 0.4);
    transform: scale(1.05);
  }
}
.card-cars__card:nth-child(odd) {
  margin-left: 0;
}
.card-cars__card:nth-child(even) {
  margin-right: 0;
}
@media (min-width: 768px) {
  .card-cars__card {
    border: 0;
    border-radius: 0;
    height: auto;
    margin: 0;
    width: 20%;
  }
}
.card-cars figure {
  display: none;
  height: 0;
  padding-top: 175%;
  position: relative;
  width: 100%;
}
@media (min-width: 768px) {
  .card-cars figure {
    display: block;
  }
}
.card-cars figure img {
  height: 100%;
  left: 50%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.card-cars__logo {
  height: 60px;
  width: 80px;
  position: static;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-cars__logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media (min-width: 768px) {
  .card-cars__logo {
    left: 50%;
    max-height: 80px;
    position: absolute;
    top: 40px;
    transform: translateX(-50%);
  }
}

.sidebar-menu {
  background: white;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .sidebar-menu.sticky {
    position: sticky;
    top: 130px;
  }
}
@media (max-width: 768px) {
  .sidebar-menu {
    display: none;
  }
}
.sidebar-menu nav ul {
  list-style: none;
}
.sidebar-menu nav ul li {
  position: relative;
  border-bottom: 1px solid #E7E7E7;
}
.sidebar-menu nav ul li.current-menu-item {
  background: #E7E7E7;
}
.sidebar-menu nav ul li.current-menu-item::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background: #C80330;
}
.sidebar-menu nav ul li:hover {
  background: #F1F1F1;
}
.sidebar-menu nav ul li:last-child {
  border-bottom: 0;
}
.sidebar-menu nav ul li a {
  padding: 25px;
  display: block;
  width: 100%;
  height: 100%;
  color: #2C2C2C;
  font-weight: bold;
}
.sidebar-menu nav ul li a:hover {
  text-decoration: none;
}

#loginform {
  width: 100%;
}
#loginform .login-username, #loginform .login-password {
  margin-bottom: 0;
  position: relative;
}
#loginform .login-username label, #loginform .login-password label {
  font-weight: 400;
  background: white;
  color: #1d4f6e;
  font-size: 14px;
  left: 15px;
  line-height: 14px;
  padding: 3px;
  position: absolute;
  top: -8px;
}
#loginform .login-username input, #loginform .login-password input {
  border: 1px solid #E7E7E7;
  height: 57px;
  line-height: 57px;
  margin-bottom: 20px;
  padding: 15px;
  width: 100%;
}
#loginform .login-username input:focus, #loginform .login-password input:focus {
  outline-color: #1d4f6e;
}
#loginform .login-submit #wp-submit {
  background: none;
  background: #C80330;
  border: 2px solid #C80330;
  border-radius: 3px;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: 0.8em;
  font-weight: 400;
  height: 50px;
  letter-spacing: 1px;
  line-height: 47px;
  margin-top: 15px;
  max-width: 100%;
  padding: 0px 30px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.2s linear;
  white-space: nowrap;
}
#loginform .login-submit #wp-submit:hover {
  background: #960224;
  border-color: #960224;
  color: white;
}

.page-template-info-page section.section, .page-template-page-bekeuring-opvragen section.section {
  background: #F9FAFB;
}

.page-template-page-mijn-business-online figure.content-image {
  height: 0;
  padding-top: 33%;
  position: relative;
  width: 100%;
  min-height: 170px;
}
.page-template-page-mijn-business-online figure.content-image .content-image__text {
  top: 0;
  left: 0;
  position: absolute;
  z-index: 1;
  padding: 25px;
}
@media (min-width: 992px) {
  .page-template-page-mijn-business-online figure.content-image .content-image__text {
    padding: 40px;
  }
}
.page-template-page-mijn-business-online figure.content-image .content-image__text h2 {
  font-size: 1.6em;
}
@media (min-width: 992px) {
  .page-template-page-mijn-business-online figure.content-image .content-image__text h2 {
    font-size: 2em;
  }
}
.page-template-page-mijn-business-online figure.content-image img {
  height: 100%;
  left: 50%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.section__404 {
  height: 60vh;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  position: relative;
}
.section__404::before {
  content: "404";
  color: rgba(231, 231, 231, 0.33);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: calc(35vw + 3em);
  font-weight: bold;
}
.section__404 .row {
  position: relative;
  z-index: 1;
}
.section__404 p {
  font-size: calc(1vw + 1em);
}
@media (min-width: 1500px) {
  .section__404 p {
    font-size: 40px;
  }
}
.section__404 h1 {
  font-size: calc(1.5vw + 1.5em);
}
@media (min-width: 1500px) {
  .section__404 h1 {
    font-size: 60px;
  }
}

.page-contact h1 {
  font-size: 22px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  padding: 20px 2px;
}
.page-contact a {
  color: #404040;
  text-decoration: none;
}

.maps {
  display: table;
  position: relative;
  width: 100%;
}
.maps #map {
  height: 500px;
  width: 100%;
}
.maps .popover {
  display: none;
  height: 100%;
  left: unset;
  max-width: 350px;
  padding: 25px;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}
.maps .popover .vestigingsinfo {
  margin-top: 50px;
}
.maps .popover a:hover {
  color: black;
}
.maps .popover h3 {
  color: black;
  margin-bottom: 20px;
}
.maps .popover p {
  color: black;
}
.maps .popover hr {
  margin: 15px 0;
}
.maps .popover .partnerlink {
  align-items: center;
  background: #C80330;
  border-radius: 50px;
  color: white;
  display: flex;
  height: 35px;
  justify-content: center;
  position: absolute;
  right: 80px;
  top: 20px;
  transition: 0.15s ease-out;
  width: 35px;
}
.maps .popover .partnerlink:hover {
  background: #960224;
  color: white;
  text-decoration: none;
  transition: 0.15s ease-out;
}
.maps .popover .close {
  color: black;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  opacity: 0.66;
  position: absolute;
  right: 0px;
  text-align: center;
  top: 0px;
  transition: 0.15s ease-out;
  width: 40px;
}
.maps .popover .close:hover {
  color: rgba(0, 0, 0, 0.33);
  transition: 0.15s ease-out;
}

.search-location {
  display: inline-block;
  height: 50px;
  position: relative;
  width: auto;
}
.search-location .search-location__results {
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  left: 11px;
  list-style: none;
  margin: 0;
  position: absolute;
  text-align: left;
  top: 50px;
  width: calc(100% - 11px);
  z-index: 2;
}
.search-location .search-location__results li {
  border-bottom: 1px solid #E7E7E7;
  padding: 5px 15px;
  position: relative;
}
.search-location .search-location__results li:hover {
  background: #E7E7E7;
}
.search-location .search-location__results li a {
  font-weight: bold;
}
.search-location .search-location__results li a:hover {
  color: black;
  text-decoration: none;
}
.search-location .search-location__results li a::before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

#form-search-location {
  margin-top: 10px;
}
@media (min-width: 540px) {
  #form-search-location {
    margin-left: 10px;
    margin-top: 0;
  }
}
#form-search-location #input-search-location {
  appearance: none;
  border: 1px solid #E7E7E7;
  border-radius: 3px;
  height: 50px;
  line-height: 50px;
  padding-left: 15px;
  width: 250px;
}
#form-search-location #input-search-location:focus {
  border-color: #1d4f6e;
}

#listings {
  display: none;
}

/* Marker tweaks */
.loading-spinner {
  display: none;
  height: 50px;
  left: calc(50% - 25px);
  position: absolute;
  top: calc(50% - 25px);
  width: 50px;
  z-index: 1;
}

.sidebar {
  border-right: 1px solid rgba(0, 0, 0, 0.25);
  display: none;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 33.3333%;
}

.pad2 {
  padding: 20px;
}

.map {
  bottom: 0;
  /*left:33.3333%;*/
  /*width:66.6666%;*/
  left: 0;
  position: absolute !important;
  top: 0;
  width: 100%;
}

.heading {
  background: #fff;
  background-color: #00853e;
  border-bottom: 1px solid #eee;
  color: #fff;
  line-height: 60px;
  min-height: 60px;
  padding: 0 10px;
}

.listings {
  height: 100%;
  overflow: auto;
  padding-bottom: 60px;
}

.listings .item {
  border-bottom: 1px solid #eee;
  display: block;
  padding: 10px;
  text-decoration: none;
}

.listings .item:last-child {
  border-bottom: none;
}

.listings .item .title {
  color: #00853e;
  display: block;
  font-weight: 700;
}

.listings .item .title small {
  font-weight: 400;
}

.listings .item.active .title,
.listings .item.active {
  background-color: #f8f8f8;
}

.marker {
  background-color: rgba(0, 0, 0, 0);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  cursor: pointer;
  height: 56px;
  width: 56px;
}

.marker.AH {
  background-image: url(marker_Ah.png);
}

.marker.Coop {
  background-image: url(marker_Coop.png);
}

.marker.Jumbo {
  background-image: url(marker_Jumbo.png);
}

.marker.Dirck {
  background-image: url(marker_Dirck.png);
}

.marker.Hoogvliet {
  background-image: url(marker_hoogvliet.png);
}

.marker.plus {
  background-image: url(marker_Plus.png);
}

.clearfix {
  display: block;
}

.clearfix:after {
  clear: both;
  content: ".";
  display: block;
  height: 0;
  visibility: hidden;
}

#loc {
  background: white;
  border: 2px solid #d6d6d6;
  border-radius: 4px;
  color: #0c0c0c;
  display: none;
  font-family: "HurmeBold", Arial, Helvetica, sans-serif;
  font-size: 17px;
  left: calc(50% - 130px);
  padding-bottom: 13px;
  padding-top: 13px;
  position: absolute;
  text-align: center;
  top: 158px;
  width: 260px;
  z-index: 1;
}

/* Marker tweaks */
.mapboxgl-popup {
  padding-bottom: 50px;
}

.mapboxgl-popup-close-button {
  color: white;
  display: block;
  right: 10px !important;
}

.mapboxgl-popup-content {
  font: 400 15px/22px "Source Sans Pro", "Helvetica Neue", Sans-serif;
  padding: 0 !important;
  width: 180px;
}

.mapboxgl-popup-content-wrapper {
  padding: 1%;
}

.mapboxgl-popup-content h3 {
  background: #626262;
  border-radius: 3px 3px 0 0;
  color: #fff200;
  display: block;
  font-weight: 700;
  margin: 0;
  margin-top: -15px;
  padding: 10px;
  text-transform: capitalize;
}

.mapboxgl-popup-content h4 {
  display: block;
  font-weight: 400;
  margin: 0;
  padding: 10px 10px 10px 10px;
  text-transform: capitalize;
}

.mapboxgl-popup-content div {
  padding: 10px;
}

.mapboxgl-container .leaflet-marker-icon {
  cursor: pointer;
}

.mapboxgl-popup-anchor-top > .mapboxgl-popup-content {
  margin-top: 15px;
}

.mapboxgl-popup-anchor-top > .mapboxgl-popup-tip {
  border-bottom-color: #ffeb00;
}

.mapboxgl-ctrl-geocoder {
  border: 2px solid #00853e;
  border: 0;
  border-radius: 0;
  margin-top: 0;
  position: relative;
  top: 0;
  width: 800px;
}

.mapboxgl-ctrl-geocoder > div {
  margin-left: 0;
  min-width: 100%;
}

.mapboxgl-ctrl-bottom-right {
  opacity: 0;
}

a.mapboxgl-ctrl-logo.mapboxgl-compact {
  display: none;
  width: 0;
}

a.mapboxgl-ctrl-logo {
  opacity: 0;
}

.mapboxgl-ctrl-bottom-right {
  opacity: 0;
}

a.mapboxgl-ctrl-logo.mapboxgl-compact {
  display: none;
  width: 0;
}

.marker {
  background-image: url("../img/map-pin.svg");
  background-repeat: no-repeat;
  cursor: pointer;
  width: 25px;
}

.page .single-item {
  background-color: white;
  padding: 25px;
}
@media (min-width: 992px) {
  .page .single-item {
    padding: 50px;
  }
}
.page .single-item h1, .page .single-item h2, .page .single-item h3 {
  text-align: left;
}

section.section.direct-reserveren {
  background: #F9FAFB;
}
@media (min-width: 992px) {
  section.section.direct-reserveren .content {
    padding-left: 80px;
    padding-right: 80px;
  }
}
@media (min-width: 1200px) {
  section.section.direct-reserveren .content {
    padding-left: 200px;
    padding-right: 200px;
  }
}

.page-template-page-bekeuringen form {
  max-width: 500px;
}
.page-template-page-bekeuringen fieldset input {
  line-height: 0px;
}
.page-template-page-bekeuringen .btn {
  display: block;
}
.page-template-page-bekeuringen .drop-area {
  position: relative;
  width: 100%;
  height: 200px;
  display: block;
  top: 0;
  left: 0;
  border: 2px dashed #333;
  text-align: center;
  line-height: 200px;
}
.page-template-page-bekeuringen .drop-area input {
  opacity: 0.5;
  margin: 0px auto;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  visibility: hidden;
}