@use "../partials/settings" as *;

.page-contact {
	
	h1 {
		font-size: 22px;
		font-weight: 400;
		line-height: 20px;
		margin: 0;
		padding: 20px 2px;
	}
	
	a {
		color: #404040;
		text-decoration: none;
	}
	
}


.content {
	// img {
	// 	margin: 0 -40px -40px -40px;
	// 	width: calc(100% + 80px);
	// }
}

.maps {
	display: table;
	position: relative;
	width: 100%;
	
	#map {
		height: 500px;
		width: 100%;
	}
	
	.popover {
		display: none;
		height: 100%;
		left: unset;
		max-width: 350px;
		padding: 25px;
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;
		z-index: 1;
		
		.vestigingsinfo {
			margin-top: 50px;
		}
		
		a {
			&:hover {
				color: black;
			}
		}
		
		h3 {
			color: black;
			margin-bottom: 20px;
		}
		
		p {
			color: black;
		}
		
		hr {
			margin: 15px 0;
		}
		
		.partnerlink {
			align-items: center;
			background: $rood;
			border-radius: 50px;
			color: white;
			display: flex;
			height: 35px;
			justify-content: center;
			position: absolute;
			right: 80px;
			top: 20px;
			transition: .15s ease-out;
			width: 35px;
			
			&:hover {
				background: darken($rood, 10%);
				color: white;
				text-decoration: none;
				transition: .15s ease-out;
			}
		}
		
		.close {
			color: black;
			cursor: pointer;
			height: 40px;
			line-height: 40px;
			opacity: .66;
			position: absolute;
			right: 0px;
			text-align: center;
			top: 0px;
			transition: .15s ease-out;
			width: 40px;
			
			&:hover {
				color: rgba(black, 0.33);
				transition: .15s ease-out;
			}
		}
	}
	
}

.search-location {
	display: inline-block;
	height: 50px;
	position: relative;
	width: auto;
	
	.search-location__results {
		background: #fff;
		border-radius: 3px;
		box-shadow: 0 3px 6px rgba(black, .20);
		left: 11px;
		list-style: none;
		margin: 0;
		position: absolute;
		text-align: left;
		top: 50px;
		width: calc(100% - 11px);
		z-index: 2;
		
		li {
			border-bottom: 1px solid #E7E7E7;
			padding: 5px 15px;
			position: relative;
			
			&:hover {
				background: #E7E7E7;
			}
			
			a {
				font-weight: bold;
				
				&:hover {
					color: black;
					text-decoration: none;
				}
				
				&::before {
					content: '';
					height: 100%;
					left: 0;
					position: absolute;
					top: 0;
					width: 100%;
					z-index: 0;
				}
			}
		}
	}
}

#form-search-location {
	margin-top: 10px;
	
	@media (min-width: $breakpoint-sm) {
		margin-left: 10px;
		margin-top: 0;
	}
	
	#input-search-location {
		appearance: none;
		border: 1px solid #E7E7E7;
		border-radius: 3px;
		height: 50px;
		line-height: 50px;
		padding-left: 15px;
		width: 250px;
		
		&:focus {
			border-color: $blauw;
		}
		
	}
}

// map
#listings {
	display: none;
}

// MAP
/* Marker tweaks */
.loading-spinner {
	display: none;
	height: 50px;
	left: calc(50% - 25px);
	position: absolute;
	top: calc(50% - 25px);
	width: 50px;
	z-index: 1;
}


.sidebar {
	border-right: 1px solid rgba(0, 0, 0, 0.25);
	display: none;
	height: 100%;
	left: 0;
	overflow: hidden;
	position: absolute;
	top: 0;
	width: 33.3333%;
}

.pad2 {
	padding: 20px;
}

.map {
	bottom: 0;
	/*left:33.3333%;*/
	/*width:66.6666%;*/
	left: 0;
	position: absolute !important;
	top: 0;
	width: 100%;
}

.heading {
	background: #fff;
	background-color: #00853e;
	border-bottom: 1px solid #eee;
	color: #fff;
	line-height: 60px;
	min-height: 60px;
	padding: 0 10px;
}

.listings {
	height: 100%;
	overflow: auto;
	padding-bottom: 60px;
}

.listings .item {
	border-bottom: 1px solid #eee;
	display: block;
	padding: 10px;
	text-decoration: none;
}

.listings .item:last-child {
	border-bottom: none;
}

.listings .item .title {
	color: #00853e;
	display: block;
	font-weight: 700;
}

.listings .item .title small {
	font-weight: 400;
}

.listings .item.active .title,
.listings .item.active {
	background-color: #f8f8f8;
}

// ::-webkit-scrollbar {
// 	background: rgba(0, 0, 0, 0.1);
// 	border-left: 0;
// 	height: 3px;
// 	width: 3px;
// }

// ::-webkit-scrollbar-track {
// 	background: none;
// }

// ::-webkit-scrollbar-thumb {
// 	background: #00853e;
// 	border-radius: 0;
// }

.marker {
	background-color: rgba(0, 0, 0, 0);
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	border: none;
	cursor: pointer;
	height: 56px;
	width: 56px;
	
}


.marker.AH {
	background-image: url(marker_Ah.png);
}

.marker.Coop {
	background-image: url(marker_Coop.png);
}

.marker.Jumbo {
	background-image: url(marker_Jumbo.png);
}

.marker.Dirck {
	background-image: url(marker_Dirck.png);
}

.marker.Hoogvliet {
	background-image: url(marker_hoogvliet.png);
}

.marker.plus {
	background-image: url(marker_Plus.png);
}

.clearfix {
	display: block;
}

.clearfix:after {
	clear: both;
	content: '.';
	display: block;
	height: 0;
	visibility: hidden;
}

#loc {
	background: white;
	border: 2px solid #d6d6d6;
	border-radius: 4px;
	color: #0c0c0c;
	display: none;
	font-family: 'HurmeBold', Arial, Helvetica, sans-serif;
	font-size: 17px;
	left: calc(50% - 130px);
	padding-bottom: 13px;
	padding-top: 13px;
	position: absolute;
	text-align: center;
	top: 158px;
	width: 260px;
	z-index: 1;
}

/* Marker tweaks */
.mapboxgl-popup {
	padding-bottom: 50px;
}

.mapboxgl-popup-close-button {
	color: white;
	display: block;
	right: 10px !important;
}

.mapboxgl-popup-content {
	font: 400 15px/22px 'Source Sans Pro', 'Helvetica Neue', Sans-serif;
	padding: 0 !important;
	width: 180px;
}

.mapboxgl-popup-content-wrapper {
	padding: 1%;
}

.mapboxgl-popup-content h3 {
	background: #626262;
	border-radius: 3px 3px 0 0;
	color: #fff200;
	display: block;
	font-weight: 700;
	margin: 0;
	margin-top: -15px;
	padding: 10px;
	text-transform: capitalize;
}

.mapboxgl-popup-content h4 {
	display: block;
	font-weight: 400;
	margin: 0;
	padding: 10px 10px 10px 10px;
	text-transform: capitalize;
}

.mapboxgl-popup-content div {
	padding: 10px;
}

.mapboxgl-container .leaflet-marker-icon {
	cursor: pointer;
}

.mapboxgl-popup-anchor-top > .mapboxgl-popup-content {
	margin-top: 15px;
}

.mapboxgl-popup-anchor-top > .mapboxgl-popup-tip {
	border-bottom-color: #ffeb00;
}

.mapboxgl-ctrl-geocoder {
	border: 2px solid #00853e;
	border: 0;
	border-radius: 0;
	margin-top: 0;
	position: relative;
	top: 0;
	width: 800px;
}

.mapboxgl-ctrl-geocoder > div {
	margin-left: 0;
	min-width: 100%;
}

.mapboxgl-ctrl-bottom-right {
	opacity: 0;
}

a.mapboxgl-ctrl-logo.mapboxgl-compact {
	display: none;
	width: 0;
}

a.mapboxgl-ctrl-logo {
	opacity: 0;
}

.mapboxgl-ctrl-bottom-right {
	opacity: 0;
}

a.mapboxgl-ctrl-logo.mapboxgl-compact {
	display: none;
	width: 0;
}

.marker {
	background-image: url('../img/map-pin.svg');
	background-repeat: no-repeat;
	cursor: pointer;
	width: 25px;
}