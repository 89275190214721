@use "settings" as *;

// slick sliders

.slick-slider {
	.slick-list {
		.slick-track {
			.slick-slide {
				height: 100%;
			}
		}
	}
	
	ul.slick-dots {
		display: flex;
		justify-content: center;
		list-style-type: none;
		margin: 0;
		padding: 1rem 0;
		width: 100%;
		
		li {
			margin: 0 .25rem;
			
			button {
				background-color: rgba($lichtgrijs, .75);
				border: none;
				border-radius: 100%;
				display: block;
				height: 1rem;
				padding: 0;
				text-indent: -9999px;
				width: 1rem;
				
				&:focus, &:active {
					border: 0;
					outline: 0;
				}
			}
			
			&.slick-active {
				button {
					//background-color: $bruin;
				}
			}
		}
	}
}

.merken {
	.slider {
		.slick-list {
			padding: 0 10% 0 0;
			
			@media (min-width: $breakpoint-xs) {
				padding: 0 20% 0 0;
			}
			
			@media (min-width: $breakpoint-lg) {
				margin: 0;
				padding: 0;
			}
		}
		
		.rechtsgebied__col {
			margin-bottom: 0;
			padding: 0 5px;
			
			@media (min-width: $breakpoint-sm) {
				padding: 0 15px;
			}
		}
	}
	
	.slider-alt {
		.slick-list {
			padding: 0 10% 0 0;
			
			@media (min-width: $breakpoint-xs) {
				padding: 0 20% 0 0;
			}
			
			@media (min-width: $breakpoint-lg) {
				margin: 0;
				padding: 0;
			}
		}
		
		.rechtsgebied__col {
			margin-bottom: 0;
			padding: 0 5px;
			
			@media (min-width: $breakpoint-sm) {
				padding: 0 15px;
			}
		}
		
		.slider-arrow {
			cursor: pointer;
			font-size: 38px;
			font-weight: bold;
			height: 38px;
			//color: $zwart;
			line-height: 38px;
			position: absolute;
			top: calc(50% - 19px);
			z-index: 10;
		}
		
		.slider-prev {
			left: 5px;
			
			@media (min-width: $breakpoint-md) {
				left: -15px;
			}
		}
		
		.slider-next {
			right: 5px;
			
			@media (min-width: $breakpoint-md) {
				right: -15px;
			}
		}
	}
}
